import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from 'react-router-dom'
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Footer, PartnersFooter, Header, FooterBeci, ReportABugModal, GiveFeedbackModal } from "../components";
import { PersonOutlined, DescriptionOutlined, PersonOutlineRounded, InfoOutlined, BugReportOutlined, StarOutlineOutlined } from '@material-ui/icons'
import { MdLogout, MdLogin, MdSupport } from 'react-icons/md'

import { isUserSignedIn, signOut } from "../services/auth";

import { t, languageFromLocale, currentLanguage, ReactGA, currentLocale, isBeci } from "../utils";

import config from "../config.json";
import { Button } from "@material-ui/core";

const routesWithoutPartners = ["/", "/fr", "/nl", "/pl", "/en", "/login"];

const NavigationPage = (props) => {
  const [currentPath, setCurrentPath] = useState("/");
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null)
  const [supportMenuAnchor, setSupportMenuAnchor] = useState(null)
  const [showReportABugModal, setShowReportABugModal] = useState(false)
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] = useState(false)
  const location = useLocation()

  useEffect(() => {
    props.history.listen((location) => {
      const { pathname } = location;
      setCurrentPath(pathname);
    });
  }, []);

  useEffect(() => {
    const { pathname } = props.history.location;
    setCurrentPath(pathname);
    ReactGA.pageview(props.history.location.pathname);

    props.refreshInteractions();
  }, [props.history.location.pathname]);

  const navigate = (path, flipLogo) => () => {
    if(flipLogo && isBeci()) {
      window.location.href = 'https://www.beci.be/'
      return
    }
    const { history } = props;
    history.push(path);
    setCurrentPath(path);
  };

  const openAccountMenu = (e) => {
    setAccountMenuAnchor(e.target);
  };

  const closeAccountMenu = () => {
    setAccountMenuAnchor(null);
  };

  // Open support menu
  const openSupportMenu = (e) => {
    setSupportMenuAnchor(e.target)
  };

  // Close support menu
  const closeSupportMenu = () => {
    setSupportMenuAnchor(null)
  };

  const doSignOut = async () => {
    const { startLoading, stopLoading } = props;
    startLoading("signingOut");
    await signOut();
    stopLoading("signingOut");
    closeAccountMenu();
    navigate("/")();
  };

  const openProfile = () => {
    navigate("/myprofile")();
    closeAccountMenu();
  };

  const openMyDocuments = () => {
    navigate("/mydocuments")();
    closeAccountMenu();
  };

  const shouldShowPartners = () => {
    const { pathname } = props.history.location;
    return (
      !!config.partners &&
      config.partners.length > 0 &&
      routesWithoutPartners.indexOf(pathname) === -1
    );
  };

  const { signedIn } = props;

  const shouldRenderLocaleSelection = () => {
    let languages = []
    for(let i in config.locales) {
      let lang = languageFromLocale(config.locales[i]).code
      if(languages.includes(lang)) {
        return true
      } 
      languages.push(lang)
    }
    return false
  }
  

  const openLanguageMenu = (e) => {
    setLanguageMenuAnchor(e.target);
  };

  const closeLanguageMenu = () => {
    setLanguageMenuAnchor(null);
  };

  const selectLocale = (locale) => () => {
    props.setLocale(locale)
    closeLanguageMenu()
  }

  const shouldShowFooter = () => {
    let show = true
    if(currentPath.includes('/document-form') && config.uses_content_form) {
      show = false
    }
    return show
  }

  const renderLocaleLabel = (locale) => {
    const language = languageFromLocale(locale).long
    const country = locale.split('_')[1].toLowerCase()
    return (
      <div className="locale-label-wrapper">
        <img src={`/images/flag_${country}.png`} className="locale-label-image" alt={`${country} flag`} />
        <span className="locale-label">{ language }</span>
      </div>
    )
  }

  const renderLanguageSelection = () => {
    if(config.locales.length > 1) {
      if(shouldRenderLocaleSelection()) {
        return (
          <>
          <Button onClick={openLanguageMenu}>{ renderLocaleLabel(currentLocale()) }</Button>
          <Menu open={Boolean(languageMenuAnchor)} onClose={closeLanguageMenu} keepMounted anchorEl={languageMenuAnchor}>
            { config.locales.map((locale, li) => {
              return (
                <MenuItem key={`locale_option_${li}`} dense onClick={selectLocale(locale)}>{ renderLocaleLabel(locale) }</MenuItem>
              )
            }) }
          </Menu>
          </>
        )
      } else {
        return config.locales.map((locale, li) => {
          return (
            <div
              key={`locale_${li}`}
              onClick={selectLocale(locale)}
              className={`nav-link--lang w-nav-link ${
                languageFromLocale(locale).code === currentLanguage()
                  ? "highlighted"
                  : ""
              }`}
            >
              {languageFromLocale(locale).code}
            </div>
          );
        })
      }
    }
    return null
  }

  const renderNavbar = (hideLanguages = false, flipLogo = false) => {
    return (
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-w-id="178ffc20-78ce-da7a-d810-f849a877aa0a"
        className="navbar w-nav"
      >
        <div className="container">
          <div className="nav-wrap">
            {!flipLogo && <div onClick={navigate("/")} className="brand-link w-nav-brand">
              {config.env !== "development" ? (
                <img
                  src={`/images/logo_large.${config.class_handle === 'affinicia' ? 'svg' : 'png'}`}
                  className={`navbar-logo ${config.class_handle ? `navbar-logo--${config.class_handle}` : ''}`}
                  alt=""
                />
              ) : null}
            </div>}
            {flipLogo && <div onClick={navigate("/", true)} className="brand-link w-nav-brand">
              {config.env !== "development" ? (
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img src={`/images/logo_large.${config.class_handle === 'beci' ? 'svg' : 'png'}`} className="navbar-logo" alt="" />
                    </div>
                    <div className="flip-card-back">
                      <p>{t('nav.brand_text')}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>}
            <nav role="navigation" className="nav-menu w-nav-menu">
              <div
                data-w-id="178ffc20-78ce-da7a-d810-f849a877aa0f"
                className="nav-link-wrap"
              >
                <div
                  onClick={navigate("/")}
                  className={`nav-link nav-link--${config.class_handle} w-nav-link${
                    currentPath === "/" ? " w--current" : ""
                  }`}
                >
                  {t("nav.home")}
                </div>
                <div className={`nav-line nav-line--${config.class_handle}`}></div>
              </div>
              {!config.single_document ? (
                <div
                  data-w-id="178ffc20-78ce-da7a-d810-f849a877aa13"
                  className="nav-link-wrap"
                >
                  <div
                    onClick={navigate("/documents")}
                    className={`nav-link nav-link--${config.class_handle} w-nav-link${
                      currentPath === "/documents" ? " w--current" : ""
                    }`}
                  >
                    {t("nav.documents")}
                  </div>
                  <div className={`nav-line nav-line--${config.class_handle}`}></div>
                </div>
              ) : null}
              {/* {!config.single_document ? (
                <div
                  data-w-id="178ffc20-78ce-da7a-d810-f849a877aa17"
                  className="nav-link-wrap"
                >
                  <div
                    onClick={navigate("/authors")}
                    className={`nav-link w-nav-link${
                      currentPath === "/authors" ? " w--current" : ""
                    }`}
                  >
                    {t("nav.authors")}
                  </div>
                  <div className="nav-line"></div>
                </div>
              ) : null} */}
              {config.authors && config.authors.length > 0 && (
                <div
                  data-w-id="178ffc20-78ce-da7a-d810-f849a877aa17"
                  className="nav-link-wrap"
                >
                  <div
                    onClick={navigate("/authors")}
                    className={`nav-link nav-link--${config.class_handle} w-nav-link${
                      currentPath === "/authors" ? " w--current" : ""
                    }`}
                  >
                    {t("nav.authors")}
                  </div>
                  <div className="nav-line"></div>
                </div>
              )}
              { config.has_issue_report ? (

                <div
                  data-w-id="178ffc20-78ce-da7a-d810-f849a877aa1b"
                  className="nav-link-wrap"
                  >
                    <div
                      onClick={navigate("/report")}
                      className={`nav-link nav-link--${config.class_handle} w-nav-link${
                        currentPath === "/report" ? " w--current" : ""
                      }`}
                    >
                      {t("nav.report_issue")}
                    </div>
                  <div className="nav-line"></div>
                </div>
              ) : null }
              <div
                data-w-id="178ffc20-78ce-da7a-d810-f849a877aa1b"
                className="nav-link-wrap"
              >
                <div
                  onClick={navigate("/contact")}
                  className={`nav-link nav-link--${config.class_handle} w-nav-link${
                    currentPath === "/contact" ? " w--current" : ""
                  }`}
                >
                  {t("nav.contact")}
                </div>
                <div className={`nav-line nav-line--${config.class_handle}`}></div>
              </div>
            </nav>
            <div className="nav-link-login-menu-btn-wrap">
              <div className="menu-btn w-nav-button">
                <img
                  src={config.partner === 'tKQgHPEaRHz1bMAlCBzk' ? "/images/burger_menu_white.svg" : "/images/burger_menu.svg"}
                  alt="burger icon"
                  className="burger-icon"
                />
                <img src={config.partner === 'tKQgHPEaRHz1bMAlCBzk' ? "/images/x-2-white.svg" : "/images/x-2.svg"} alt="" className="close-icon" />
              </div>
              <div className="nav-link-wrap--lang">
                { (!config.requires_auth || isUserSignedIn()) &&
                  <Fragment>
                    <div
                      onClick={signedIn ? openAccountMenu : navigate("/login")}
                      className="nav-btn btn btn--red w-inline-block"
                    >
                      <h4 className="nav__h">
                        {signedIn ? t("general.my_account") : t("nav.connect")}
                      </h4>
                      {isBeci() && <span className="login-profile-icon">{signedIn ? <PersonOutlineRounded /> : <MdLogin />}</span>}
                      <div className="growing-div"></div>
                    </div>
                    {isBeci() && signedIn && (
                      <div
                        onClick={openSupportMenu}
                        className="nav-btn btn btn--red w-inline-block"
                      >
                        <h4 className="nav__h support__h">
                          <InfoOutlined />
                        </h4>
                        <div className="growing-div"></div>
                      </div>
                    )}
                  </Fragment>
                }
                { !hideLanguages && renderLanguageSelection() }
              </div>
            </div>
          </div>
        </div>
        <div className="nav__g-line"></div>
        <Menu
          id="add-question-menu"
          anchorEl={accountMenuAnchor}
          keepMounted
          open={Boolean(accountMenuAnchor)}
          onClose={closeAccountMenu}
          className={`account-menu--${config.class_handle}`}
        >
          <MenuItem dense onClick={openProfile}>
            {isBeci() && <PersonOutlined />}
            {t("nav.my_profile")}
          </MenuItem>
          <MenuItem dense onClick={openMyDocuments}>
            {isBeci() && <DescriptionOutlined />}
            {t("nav.my_documents")}
          </MenuItem>
          <MenuItem dense onClick={doSignOut}>
            {isBeci() && <MdLogout />}
            {t("nav.sign_out")}
          </MenuItem>
        </Menu>

        {/* Support menu  */}
        {isBeci() && <Menu
          id="support-menu"
          anchorEl={supportMenuAnchor}
          keepMounted
          open={Boolean(supportMenuAnchor)}
          onClose={closeSupportMenu}
          className={`account-menu--${config.class_handle}`}
        >
          <MenuItem dense onClick={() => {closeSupportMenu(); navigate('/help-center')()}}>
            <MdSupport />
            {t("support.help_center")}
          </MenuItem>
          <MenuItem dense onClick={() => {closeSupportMenu(); setShowReportABugModal(true)}}>
            <BugReportOutlined />
            {t("support.report_bug")}
          </MenuItem>
          <MenuItem dense onClick={() => {closeSupportMenu(); setShowGiveFeedbackModal(true)}}>
            <StarOutlineOutlined />
            {t("support.give_feedback")}
          </MenuItem>
        </Menu>}
      </div>
    )
  }

  return (
    <div className="sec-wrap">
      {isBeci()
        ? !location?.pathname.includes('/document-form') ? <Header navbar={renderNavbar} navigate={navigate} languages={renderLanguageSelection} accountMenuAnchor={accountMenuAnchor} closeAccountMenu={closeAccountMenu} openProfile={openProfile} openMyDocuments={openMyDocuments} doSignOut={doSignOut} /> : null
        : renderNavbar()
      }
      {props.children}
      {shouldShowPartners() ? <PartnersFooter /> : null}
      {isBeci() ? <FooterBeci /> : shouldShowFooter() ? <Footer navigate={navigate} /> : null}
      {showReportABugModal && <ReportABugModal 
        onClose={() => setShowReportABugModal(false)} 
        showSnackbar={props.showSnackbar}
        startLoading={props.startLoading}
        stopLoading={props.stopLoading}
      />}
      {showGiveFeedbackModal && <GiveFeedbackModal 
        onClose={() => setShowGiveFeedbackModal(false)} 
        showSnackbar={props.showSnackbar}
        startLoading={props.startLoading}
        stopLoading={props.stopLoading}
      />}
    </div>
  );
};

export default NavigationPage;
