import { useState, useRef, useEffect } from 'react'
import { ChevronRight } from '@material-ui/icons'
import moment from 'moment'

import Button from './UI/Button'
import Input from './UI/Input'
import CustomTooltip from './UI/CustomTooltip'
import { isOverflown, areSectionConditionsMet, CONTACT_ID_VARIABLES } from '../utils'

const SPVariables = ({ variableTypes, totalProgress = 0, onItemClick, documentValues, onSynthesisClicked, activeVariable, scrollingPosition, onSetScrollPosition, prevScrollPosition, templateData, onSectionClick, scrollingToItem }) => {
  const [search, setSearch] = useState('')
  const [filteredVariables, setFilteredVariables] = useState([])
  const [variableSections, setVariableSections] = useState([])
  // const [prevActiveEl, setPrevActiveEl] = useState(null)
  const spVariablesEl = useRef()
  const spVariablesBodyEl = useRef()

  useEffect(() => {
    if(!templateData) {
      return
    }
    const sections = [...templateData.sections]
    const sectionsHeadings = sections.filter(s => s.type.startsWith('heading'))
    const sectionsUpdated = []
    if(sectionsHeadings.length === 0) {
      sectionsUpdated.push(sections)
    }else if(sections.indexOf(sectionsHeadings[0]) > 0) {
      sectionsUpdated.push(sections.slice(0, sections.indexOf(sectionsHeadings[0])))
    }
    sectionsHeadings.forEach((s, i, allArr) => {
      const sectionIndex = sections.indexOf(s)
      const arr = sections.indexOf(allArr[i+1]) !== -1 ? sections.slice(sectionIndex, sections.indexOf(allArr[i+1])) : sections.slice(sectionIndex)
      arr[0].sectionIndex = `section-${sectionIndex}`
      sectionsUpdated.push(arr)
    });
    const sectionsArr = []
    
    sectionsUpdated.forEach((s) => {
      const vars = []
      let obj = { title: '', fields: [], progress: 0, vars: [] }

      let idx = 0;
      for(let secField in s) {
        const f = s[secField]
        if(!areSectionConditionsMet(f, documentValues)) {
          idx++
          continue
        }
        if(idx === 0 && f.type.startsWith('heading')) {
          const fields = f.content.match(/\{d\.([^}]*)\}/g)
          const title = fields ? fields.length > 0 ? f.content.replace(fields[0], '') : f.content : f.content
          obj.title = title
          obj.index = f.sectionIndex
        }
        if(f.variables || f.variable){
          if(f.variable) {
            if(f.repeatable_section_id && documentValues[f.repeatable_section_id]) {
              documentValues[f.repeatable_section_id].forEach((item, index) => {
                let splittedVar = f.variable.split('_')
                let lastEl = splittedVar[splittedVar.length - 1] 
                if(lastEl !== 'id') {
                  vars.push({variable: f.variable, index, belongs_to: f.repeatable_section_id})
                  if(!CONTACT_ID_VARIABLES.includes(f.variable)) {
                    obj.fields.push(f)
                  }
                }
              })
            }else {
              if(!CONTACT_ID_VARIABLES.includes(f.variable)) {
                obj.fields.push(f)
              }
              if(!vars.includes(f.variable)) {
                vars.push(f.variable)
              }
            }
          }else {
            if(f.repeatable_section_id && documentValues[f.repeatable_section_id]) {
              documentValues[f.repeatable_section_id].forEach((item, index) => {
                f.variables.forEach((v) => {
                  let splittedVar = v.variable.split('_')
                  let lastEl = splittedVar[splittedVar.length - 1] 
                  if(lastEl !== 'id') {
                    vars.push({variable: v.variable, index, belongs_to: f.repeatable_section_id});
                    if(!CONTACT_ID_VARIABLES.includes(v.variable)) {
                      if(obj.fields.indexOf(f) === -1) {
                        obj.fields.push(f);
                      }
                    } 
                  }
                })
              })
            }else {
              f.variables.forEach((v) => {
                if(!vars.includes(v.variable)) {
                  vars.push(v.variable)
                }
                if(!CONTACT_ID_VARIABLES.includes(v.variable)) {
                  if(obj.fields.indexOf(f) === -1) {
                    obj.fields.push(f)
                  }
                }              
              });
            }
          }
        }
        if(f.items) {
          for(let i in f.items) {
            if(f.items[i].variable) {
              obj.fields.push(f.items[i])
              if(!vars.includes(f.items[i].variable)) {
                vars.push(f.items[i].variable)
              }
            } else if(f.items[i].variables) {
              f.items[i].variables.forEach((v) => {
                if(obj.fields.indexOf(f.items[i]) === -1) {
                  obj.fields.push(f.items[i])
                }
                if(!vars.includes(v.variable)) {
                  vars.push(v.variable)
                }
              })
            }
          }
        }
        idx++
      }
      let p = 0;
      for(let i in vars) {
        if(typeof vars[i] === 'string') {
          if(!!documentValues[vars[i]]) {
            p += 1 / vars.length
          }
        }else {
          if(documentValues[vars[i].belongs_to] && !!documentValues[vars[i].belongs_to][vars[i].index][vars[i].variable]) {
            p += 1 / vars.length
          }
        }
      }
      obj.progress = vars.length > 0 ? (p * 100).toFixed(0) : null;
      sectionsArr.push(obj)
    })

    // console.log('set filtered variables')

    setVariableSections(sectionsArr)
    setFilteredVariables(sectionsArr)
    // eslint-disable-next-line
  }, [templateData, documentValues])

  // Search handler
  const searchChangeHandler = (e) => {
    const value = e.target.value
    setSearch(value)
    const copyVarSections = [...variableSections]
    // const filtered = [...variables].filter(v => v.includes(value.trim().toLowerCase()));
    const filtered = copyVarSections.map((varSection, i) => {
      const section = {...varSection}
      if(!section.title.toLowerCase().includes(value.trim().toLowerCase())) {
        section.title = ''
      }else {
        section.title = variableSections[i].title
      }
      const sectionFields = [];
      [...section.fields].forEach(f => {
        const sf = {...f}
        if(sf.variables) {
          const varsArr = [...sf.variables]
          const updatedVarsArr = []
          varsArr.forEach(v => {
            if(v.variable.toLowerCase().includes(value.trim().toLowerCase())) {
              updatedVarsArr.push(v)
            }
          })
          if(updatedVarsArr.length > 0) {
            sf.variables = updatedVarsArr
            sectionFields.push(sf)
          }
        }else {
          if(sf.variable.toLowerCase().includes(value.trim().toLowerCase())) {
            sectionFields.push(sf)
          }
        }
      })
      section.fields = sectionFields
      return section
    });
    setFilteredVariables(filtered)
  }

  return (
    <div className="document-detail-sp-variables" ref={spVariablesEl}>
      <div className="document-detail-sp-variables__head-search-wrapper">
        <div className="document-detail-sp-variables__head">
          <h4>Variables</h4>
          {/* <Button
            text="Voir la synthèse" 
            outlinePrimaryLight
            xSmall
            regular
            onButtonClick={onSynthesisClicked}
          /> */}
        </div>
        <div className="document-detail-sp-variables__progress">
          <div className="document-detail-sp-variables__progress_inner">
            <div style={{width: `${(totalProgress * 100).toFixed(0)}%`}}></div>
          </div>
          <p>{(totalProgress * 100).toFixed(0)}%</p>
        </div>
        <div className="document-detail-sp-variables__search">
          <Input 
            value={search}
            onChange={searchChangeHandler}
            name="search"
            placeholder="Rechercher"
            medium
            whiteBackground
          />
        </div>
      </div>

      <div className="document-detail-sp-variables__body" ref={spVariablesBodyEl}>
        {filteredVariables.map((varSection, i) => {
          return <SPVariableSection 
            key={i} 
            varSection={varSection} 
            onSectionClick={onSectionClick} 
            activeVariable={activeVariable} 
            documentValues={documentValues}
            onItemClick={onItemClick}
            variableTypes={variableTypes} 
          />
        })}
      </div>
    </div>
  )
}


// SP Variable section component
const SPVariableSection = ({ varSection, onSectionClick, activeVariable, documentValues, onItemClick, variableTypes, openVarsDefault = false }) => {
  const [openVars, setOpenVars] = useState(openVarsDefault);
  let sectionClass = 'document-detail-sp-variables__body_section';

  const sectionClickHandler = (idx) => {
    onSectionClick(idx);
  }

  if(openVars) {
    sectionClass += ' document-detail-sp-variables__body_section--active';
  }

  return (
    <div className={sectionClass} data-index={varSection.index}>
      {varSection.title && <SPVariableHeading 
        onSectionClick={(idx) => sectionClickHandler(idx)} 
        varSection={varSection} 
        activeVariable={activeVariable} 
        index={varSection.index} 
        setOpenVars={setOpenVars}
        openVars={openVars}
      />}
      {openVars && varSection.fields.length > 0 && <ul>
          {varSection.fields.map((f, i) => {
            const output = [];
            if(f.variables) {
              // console.log(f.variables)
              if(f.repeatable_section_id && documentValues[f.repeatable_section_id]) {
                documentValues[f.repeatable_section_id].forEach((_, index) => {
                  f.variables.forEach((v, i) => {
                    const { variable, display_name, type, question, idx, tooltip } = v
                    let displayName = type === 'question' ? question : display_name || tooltip
                    let splittedVar = v.variable.split('_')
                    let lastEl = splittedVar[splittedVar.length - 1]
  
                    if(lastEl !== 'id') {
                      output.push(
                        <SPVariableListItem key={`${idx}-${index}`} value={documentValues[f.repeatable_section_id][index][variable]} variable={variable} onItemClick={() => onItemClick(v.idx)} activeVariable={activeVariable} isVarDate={variableTypes[variable] === 'date'} idx={v.idx} displayName={displayName} />
                      )
                    }
                  })
                })
              }else {
                f.variables.forEach((v, i) => {
                  const { variable, display_name, type, question, tooltip } = v
                  let displayName = type === 'question' ? question : display_name || tooltip

                  output.push(
                    <SPVariableListItem key={`${variable}-${i}`} value={documentValues[variable]} variable={variable} onItemClick={() => onItemClick(v.idx)} activeVariable={activeVariable} isVarDate={variableTypes[variable] === 'date'} idx={v.idx} displayName={displayName} />
                  )
                  
                });
              }
            }else {
              const { variable, display_name, type, question } = f;
              let displayName = type === 'question' ? question : display_name
              if(f.repeatable_section_id && documentValues[f.repeatable_section_id]) {
                for(let rep_i in documentValues[f.repeatable_section_id]) {
                  output.push(<SPVariableListItem key={`${f.repeatable_section_id}-${rep_i}-${variable}-${i}`} variable={variable} value={documentValues[variable]} onItemClick={() => onItemClick(`${f.idx}-${rep_i}`)} activeVariable={activeVariable} isVarDate={variableTypes[variable] === 'date'} idx={`${f.idx}-${rep_i}`} displayName={displayName} />)
                }
              } else {
                output.push(<SPVariableListItem key={`${variable}-${i}`} variable={variable} value={documentValues[variable]} onItemClick={() => onItemClick(f.idx)} activeVariable={activeVariable} isVarDate={variableTypes[variable] === 'date'} idx={f.idx} displayName={displayName} />)
              }
            }
            return output;
          })}
        </ul>
      }
    </div>
  );
}

// SP Variable heading component
const SPVariableHeading = ({ onSectionClick, varSection, activeVariable, index, setOpenVars, openVars }) => {
  const headingEl = useRef();
  let headingClass = '';

  if(index === activeVariable) {
    headingClass = 'active';
  }
  // console.log(index, activeVariable);

  const output = (
    <h2 
      ref={headingEl} 
      onClick={() => onSectionClick(varSection.index)} 
      className={headingClass}
      data-id={index}
    >
      {varSection.title}
      {varSection.progress ? <span className="progress">{`${varSection.progress}%`}</span> : null}
      {varSection.progress ? <CustomTooltip content={!openVars ? 'Afficher' : 'Masquer'}><span className="icon" onClick={() => setOpenVars(!openVars)}><ChevronRight /></span></CustomTooltip> : null}
    </h2>
  );

  // return isOverflown(headingEl.current) ? <CustomTooltip content={varSection.title} fixed>{output}</CustomTooltip> : output; 
  return output;
}

// SP Variable list item component
const SPVariableListItem = ({ onItemClick, variable, value, activeVariable, isVarDate, displayName, idx }) => {
  const variableKeyEl = useRef()
  const variableTextEl = useRef()

  return(
    <li onClick={() => onItemClick(idx)} className={idx === activeVariable ? 'active' : ''} data-id={idx}>
      <CustomTooltip content={displayName || variable} position="top-left" fixed hideTooltip={!isOverflown(variableKeyEl.current)}>
        <p ref={variableKeyEl}>{displayName || variable}</p>
      </CustomTooltip>
      {(value && isVarDate && moment(value, moment.ISO_8601, true).isValid()) 
        ? <p className="input-value">{moment(value).format('DD MMM YYYY') }</p>
        : Array.isArray(value) 
          ? <p className="input-value">
            {value.map((v, i) => <SPVariableListSpanEl key={i} value={v} /> ) }
            </p>
          : value 
            ? <CustomTooltip content={value} position="top-right" fixed hideTooltip={!isOverflown(variableTextEl.current)}>
                <p className="input-value" ref={variableTextEl}>{value}</p>
              </CustomTooltip>
            : <p className="input-value">{value || ' '}</p> }
    </li>
  );
}

// SP Variable list span component
const SPVariableListSpanEl = ({ value }) => {
  const spanEl = useRef()

  const output = <span ref={spanEl}>{value}</span>

  return isOverflown(spanEl.current) ? <CustomTooltip content={value} fixed position="top-right">{output}</CustomTooltip> : output
}

export default SPVariables