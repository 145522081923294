import React, { useEffect, useState } from 'react'

import { sendConactForm } from '../services/database'

import { t, currentLanguage, isBeci } from '../utils'

import config from '../config.json'

const Contact = (props) => {

  const { startLoading, stopLoading, showSnackbar, user, report } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if(!!user) {
      if(!isEmpty(user.title)) {
        for(let i in titles) {
          if(titles[i].value === user.title.toLowerCase()) {
            setTitle(user.title.toLowerCase())
            break
          }
        }
      }
      if(!isEmpty(user.name_first)) {
        setNameFirst(user.name_first)
      }
      if(!isEmpty(user.name_last)) {
        setNameLast(user.name_last)
      }
      if(!isEmpty(user.phone)) {
        setPhone(user.phone)
      }
      if(!isEmpty(user.email)) {
        setEmail(user.email)
      }
      if(!isEmpty(user.company_name)) {
        setOrg(user.company_name)
      }
      setMessage('')
    } else {
      setTitle('')
      setNameFirst('')
      setNameLast('')
      setPhone('')
      setEmail('')
      setOrg('')
      setMessage('')
    }
  }, [props.user])

  const [title, setTitle] = useState('')
  const [nameFirst, setNameFirst] = useState('')
  const [nameLast, setNameLast] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [org, setOrg] = useState('')  
  const [message, setMessage] = useState('')

  const titles = () => {
    if(isBeci()) {
      return [
        { value: 'mr', label: t('contact.mr') },
        { value: 'mrs', label: t('contact.mrs') },
        { value: 'ms', label: t('contact.ms') },
      ]
    }else {
      if(currentLanguage() == 'nl') {
        return [
          { value: 'mr', label: t('contact.mr') },
          { value: 'mrs', label: t('contact.mrs') },
        ]
      } else {
        return [
          { value: 'mr', label: t('contact.mr') },
          { value: 'mrs', label: t('contact.mrs') },
          { value: 'ms', label: t('contact.ms') },
        ]
      }
    }
  }

  const titleButton = (t) => {
    return (
      <label key={t.value} className="w-checkbox f__checkbox f__checkbox--left">
        <div onClick={() => setTitle(t.value)} className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 ${title === t.value ? 'w--redirected-checked' : ''}`}></div>
        <h5 className={`checkbox__h ${title === t.value ? 'w--redirected-checked' : ''}`}>{ t.label }</h5>
      </label>
    )
  }

  const submit = async () => {
    let incompleteMessage
    if(isEmpty(title)) {
      incompleteMessage = t('contact.empty_title')
    } else if(isEmpty(nameFirst)) {
      incompleteMessage = t('contact.empty_name_first')
    } else if(isEmpty(nameLast)) {
      incompleteMessage = t('contact.empty_name_last')
    } else if(isEmpty(phone)) {
      incompleteMessage = t('contact.empty_phone')
    } else if(isEmpty(email)) {
      incompleteMessage = t('contact.empty_email')
    } else if(isEmpty(org)) {
      incompleteMessage = t('contact.empty_org')
    } else if(isEmpty(message)) {
      incompleteMessage = t('contact.empty_message')
    }
    if(!!incompleteMessage) {
      showSnackbar({ text: incompleteMessage, color: 'warning' })
      return
    }
    startLoading('sendingContact', t('contact.sending'))
    const formData = {
      title,
      nameFirst,
      nameLast,
      phone,
      email,
      org,
      message,
      partnerId: config.partner,
      partnerName: config.name
    }
    if(config.contact_handle) {
      formData.contactHandle = config.contact_handle
    }
    if(isBeci()) {
      formData.removeCC = true
    }
    let result = await sendConactForm(formData)
    if(!!result.error) {
      showSnackbar({ text: t(`firebase_error.${result.error.code}`), color: 'error' })
    } else {
      showSnackbar({ text: t('contact.success'), color: 'success' })
    }
    stopLoading('sendingContact')
  }

  const isEmpty = (value) => {
    return !value || value === ''
  }
  
  return (
    <div className="sec">
      <div className="container">
        <div className="form-card">
          <div className="form__cont">
            <div className="pop__title-wrap">
              <h1 className="pop__h1">{ report ? t('nav.report_issue') : t('contact.title') }</h1>
            </div>
            <div className="f__form-wrap">
              <div className="form w-form">
                <div id="email-form" name="email-form" data-name="Email Form">
                  <div className="checkbox-wrap">
                    { titles().map((t, ti) => {
                      return titleButton(t)
                    })}
                  </div>
                  <div className="f__row">
                    <div className="f__cont-wrap">
                      <label htmlFor="nameFirst" className="f-form__label">{ t('general.name_first') }</label>
                      <input type="text" value={nameFirst} onChange={e => setNameFirst(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="firstName" data-name="firstName" id="firstName" required="" /></div>
                    <div className="f__cont-wrap f__cont-wrap--right">
                      <label htmlFor="nameLast" className="f-form__label">{ t('general.name_last') }</label>
                      <input type="text" value={nameLast} onChange={e => setNameLast(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="lastName" data-name="lastName" id="lastName" required="" /></div>
                  </div>
                  <div className="f__row">
                    <div className="f__cont-wrap">
                      <label htmlFor="phone" className="f-form__label">{ t('contact.phone') }</label>
                      <input type="text" value={phone} onChange={e => setPhone(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="phone" data-name="phone" id="phone" required="" /></div>
                    <div className="f__cont-wrap f__cont-wrap--right">
                      <label htmlFor="email" className="f-form__label">{ t('general.email') }</label>
                      <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="email" data-name="email" id="email" required="" /></div>
                  </div>
                  <div className="f__row">
                    <div className="f__cont-wrap">
                      <label htmlFor="societe" className="f-form__label">{ t('contact.org') }</label>
                      <input type="text" value={org} onChange={e => setOrg(e.target.value)} className="f-form__text-field w-input" maxLength="256" name="societe" data-name="societe" id="societe" required="" /></div>
                  </div>
                  <div className="f__row">
                    <div className="f__cont-wrap textarea">
                      <label htmlFor="message" className="f-form__label">{report ? t('report_issue.message_title') : t('contact.message')}</label>
                      <textarea placeholder={report ? t('report_issue.message_title') : t('contact.message_placeholder')} value={message} onChange={e => setMessage(e.target.value)} maxLength="5000" id="message" name="message" className="textarea w-input"></textarea></div>
                  </div>
                  <div className="btn-wrap">
                    <div className="f__btn-line-wrap">
                      <div onClick={submit} type="submit" className="form__btn btn--full pop-up__btn w-button">{ t('contact.send') }</div>
                      <div className="red-line"></div>
                    </div>
                  </div>
                </div>
              </div>
              { config.contact_terms ? (
                <div className="contact-terms">
                  { t('contact.terms') }
                </div>
              ) : null }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact