import React, { useContext, useState, useEffect } from 'react' 
import { useParams, useHistory } from 'react-router-dom'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import Loader from '../../components/UI/Loader'
import { SupportPageLayout } from '../../components'
import { HelpContext } from '../../context/help'
import { sortArrayOfObjects } from '../../utils'
import { t, currentLanguage } from '../../utils'

const HelpCategory = (props) => {
  const { categories, categoriesFetched, articlesArr, articlesFetched } = useContext(HelpContext)
  const { id } = useParams()
  const history = useHistory()
  const [category, setCategory] = useState({})
  const [categoryArticles, setCategoryArticles] = useState([])
  const [categoryDoesNotExist, setCategoryDoesNotExist] = useState(false)
  const [sortedArticles, setSortedArticles] = useState([])
  const [sortBy, setSortBy] =  useState('sort_az')

  // Set category from id
  useEffect(() => {
    if(categoriesFetched) {
      if(categories[id]) {
        setCategory(categories[id])
      }else {
        setCategoryDoesNotExist(true)
      }
    }
  }, [id, categories, categoriesFetched])

  // Set category articles
  useEffect(() => {
    if(articlesFetched) {
      let filtered = articlesArr.filter(article => article.category === id)
      filtered = sortArrayOfObjects(filtered, `title.${currentLanguage()}`, 'desc')
      setCategoryArticles(filtered)
      setSortedArticles(filtered)
    }
  }, [articlesFetched, articlesArr, id])

  // Article click handler
  const articleClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/help-article/${id}`)
  }

  // Sorting
  const sortArticlesChangeHandler = (e) => {
    const value = e.target.value
    setSortBy(e.target.value)
    if(value !== '') {
      let arr = sortArrayOfObjects([...categoryArticles], value, value === 'readCount' ? 'asc' : 'desc')
      setSortedArticles(arr)
    }else {
      setSortedArticles(categoryArticles)
    }
  }

  return (
    <SupportPageLayout withSidebar showBackButton user={props.user}>
      {!articlesFetched && !categoriesFetched && <Loader normal small primary />}
      {Object.keys(category).length > 0 && 
        <div className="help-category">
          <div className="help-category__head">
            <h2>{category.title[currentLanguage()] || 'Missing translation'}</h2>
            <select value={sortBy} onChange={sortArticlesChangeHandler} className="sort-select">
              <option value={`title.${currentLanguage()}`}>{t('support.sort_az')}</option>
              <option value="readCount">{t('support.sort_by_popularity')}</option>
            </select>
            <div className="help-category__head_desc">
              <p>{category.description[currentLanguage()] || ''}</p>
            </div>
          </div>
          <div className="help-category__body">
            <ul>
              {sortedArticles.map((article, idx) => (
                <li key={idx}><a href="/#" onClick={(e) => articleClickHandler(e, article.id)}><DescriptionIcon /> {article.title[currentLanguage()] || 'Missing translation'}</a></li>
              ))}
            </ul>
          </div>
        </div>
      }
      {categoryDoesNotExist &&
        <div className="support-page__no-data">
          <p>{t('support.category_doesnt_exist', { id: id })}</p>
        </div>
      }
    </SupportPageLayout>
  )
}

export default HelpCategory