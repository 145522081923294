import React, { useContext, useEffect, Fragment } from 'react' 
import { useHistory } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { SearchForm } from '..'
import { HelpContext } from '../../context/help'
import { t, currentLanguage } from '../../utils'

import config from '../../config.json'

const SupportPageLayout = ({ title = t('support.help_center'), children, withSidebar, hideCategories, showBackButton, user }) => {
  const { categoriesArr, selectedCategory, setSelectedCategory, articlesFetched, fetchHelpArticles, categoriesFetched, fetchHelpCategories } = useContext(HelpContext)
  const history = useHistory()
  let body = null 

  // Fetch articles if they are not fetched yet
  useEffect(() => {
    if(Object.keys(user).length && !articlesFetched) {
      fetchHelpArticles(config.support_partner_handle)
    }
  }, [articlesFetched, fetchHelpArticles, user])

  // Fetch categories if they are not fetched yet
  useEffect(() => {
    if(Object.keys(user).length && !categoriesFetched) {
      fetchHelpCategories(config.support_partner_handle)
    }
  }, [categoriesFetched, fetchHelpCategories, user])

  // Category click handler
  const categoryClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/help-category/${id}`)
    setSelectedCategory(id)
  }

  // Back button click handler
  const backButtonClickHandler = e => {
    e.preventDefault()
    history.push('/help-center')
  }

  // Body 
  if(withSidebar) {
    body = (
      <div className="support-page__body support-page__body--flex">
        <aside>
          <SearchForm type="small" />
          {!hideCategories && 
            <Fragment>
              <h4 onClick={() => history.push('/help-center')}>{t('support.categories')}</h4>
              <ul>
                {categoriesArr.map((category, idx) => (
                  <li key={idx} className={selectedCategory === category.id ? 'active' : ''}>
                    <a href="/#" onClick={(e) => categoryClickHandler(e, category.id)}>
                      {category.title[currentLanguage()] || 'Missing translation'} <ChevronRightIcon />
                    </a>
                  </li>
                ))}
              </ul>
            </Fragment>
          }
          {showBackButton && <a href="/#" onClick={backButtonClickHandler} className="back"><ChevronLeftIcon /> {t('support.back_to_help_center')}</a>}
        </aside>
        <main>
          { children }
        </main>
      </div>
    )
  }else {
    body = <div className="support-page__body">{children}</div>
  }

  return (
    <div className="support-page">
      <div className="support-page__container">
        <div className="support-page__head">
          <h1>{title}</h1>
        </div>
        {body}
      </div>
    </div>
  )
}

export default SupportPageLayout