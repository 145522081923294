import React, { useState, useEffect, useCallback, useLayoutEffect, Fragment } from "react";
import { Link } from 'react-router-dom'
import { sortedArrayFromObject, idFromName, t, currentLocale } from "../utils";
import Collapse from "@material-ui/core/Collapse";
import config from "../config.json";
import arrowUpBeci from '../assets/images/arrow_up_beci.svg'

const Documents = (props) => {
  const [topNav, setTopNav] = useState({ show: false });

  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [categories, setCategories] = useState([]);
  const [categoryDocuments, setCategoryDocuments] = useState({});
  const [refs, setRefs] = useState([]);

  useEffect(() => {
    let documentsArray = sortedArrayFromObject(
      props.documents,
      "updated",
      true
    );

    let categoriesArray = sortedArrayFromObject(props.categories, "name", true);
    let filteredCategories = categoriesArray.filter(cat => cat.meta.locale === currentLocale())
    setCategories(filteredCategories);

    let r = [];
    for (let i in categoriesArray) {
      r.push(React.createRef());
    }
    setRefs([...r]);
    setupDocuments(documentsArray);
  }, [props.categories, props.documents]);

  const checkPosition = (elements) => {
    for(let i = 0; i < elements.length; i++) {
      const el = elements[i].current
      const categoryColEl = document.querySelector(`#category_col_${i}`)
      if(el && el.offsetTop - 74 <= window.scrollY && el.offsetTop + el.offsetHeight >= window.scrollY) {
        if(categoryColEl) {
          categoryColEl.classList.add('highlighted')
        }
      }else {
        if(categoryColEl) {
          categoryColEl.classList.remove('highlighted')
        }
      }
    }
  }

  const handleScroll = useCallback((e) => {
    if (window.scrollY > 350) {
      setTopNav({ show: true });
      checkPosition(refs)
    } else if (window.scrollY <= 350) {
      setTopNav({ show: false });
    }
  }, [refs]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    props.refreshInteractions();
  });

  const setupDocuments = (docs) => {
    let cd = {};
    for (let i in docs) {
      if (!cd[docs[i].category]) {
        cd[docs[i].category] = [];
      }

      cd[docs[i].category].push(docs[i]);
    }

    setCategoryDocuments({ ...cd });
  };

  const openDocumentDetail = (document) => (e) => {
    e && e.preventDefault()

    const { history } = props;
    const link = props.shouldPushToSubscription(document.category)
      ? { pathname: "/subscription", state: { documentId: document.id } }
      : `/document/${document.id}`;
    history.push(link);
  };

  const openContact = () => {
    const { history } = props;
    history.push(`/contact`);
  };

  useEffect(() => {
    const { params } = props.location;
    if (!!params && params.category) {
      scrollTo(params.category);
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [categories]);

  const scrollTo = (hash) => {
    const { location } = props;
    location.hash = hash;
    let categoryIndex = -1;
    for (let i in categories) {
      if (`#${idFromName(categories[i].name)}` === hash) {
        categoryIndex = i;
        break;
      }
    }
    setSelectedCategory(categoryIndex);
    let ref;
    if (categoryIndex !== -1) {
      ref = refs[categoryIndex];
      window.scrollTo({
        top: ref.current.offsetTop - 74,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="sec-wrap">
      <Collapse in={topNav.show} className="sticky-collapse">
        <div className="tabs">
          <div className="row row--tabs">
            {categories.map((category, ci) => {
              return (
                <div
                  onClick={() => scrollTo(`#${idFromName(category.name)}`)}
                  key={`category_col_${ci}`}
                  id={`category_col_${ci}`}
                  className={`tabs__link w-inline-block`}
                  // className={`tabs__link w-inline-block ${
                  //   selectedCategory == ci ? "highlighted" : ""
                  // }`}
                >
                  <div className="tabs__cont" style={{ opacity: 1 }}>
                    <div className="t-icons__wrap">
                      <img
                        src={category.icon}
                        alt="cat-icon"
                        width="75"
                        className="tabs__icon"
                      />
                    </div>
                    <div className="docs_navbar_title_container">
                      <h4
                        className={`d-cont__h4 ${
                          selectedCategory == ci ? "highlighted" : ""
                        }`}
                      >
                        {category.name}
                      </h4>
                    </div>
                  </div>
                  <div className="tabs__g-line"></div>
                  <div className="tabs__s-line"></div>
                </div>
              );
            })}
          </div>
        </div>
      </Collapse>
      <h4 className={`sec__title sec__title--${config.class_handle}`}>
        {" "}
        {t("documents.title")}{" "}
      </h4>
      <div className="sec">
        <div className="container">
          <h1 className={`hero__h hero__h--${config.class_handle}`}>{t("documents.subtitle")}</h1>
          <div className="row row--doc">
            <div className="blue-box-wrap">
              <div className="b-row">
                {categories.map((category, ci) => {
                  return (
                    <div
                      onClick={() => scrollTo(`#${idFromName(category.name)}`)}
                      key={`blue_box_cat_${ci}`}
                      className="d-hero-link d-hero-link--static w-inline-block"
                    >
                      <div className="d-hero-cont d-hero-cont--static">
                        <div
                          className="d-hero-img-wrap d-hero-img-wrap--static"
                          style={{ backgroundImage: `url(${category.image})` }}
                        ></div>
                        {props.renderPaymentLabel(category.id)}
                        <div className="d-title-wrap">
                          <h4 className="d-cont__h4">{category.name}</h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {categories.map((category, ci) => {
            return (
              <div
                key={`d-cont-wrap-cat_${ci}`}
                id={idFromName(category.name)}
                ref={refs[ci]}
                data-scroll="mid"
                className="d-cont-wrap"
              >
                <h2 className="sec__h2">{category.name}</h2>
                <p className="d-cont__p">{category.description}</p>
                {!!categoryDocuments[category.id] &&
                categoryDocuments[category.id].length > 0 ? (
                  categoryDocuments[category.id].map((document, di) => {
                    return (
                      // <div onClick={openDocumentDetail(document)} className="d-title-link w-inline-block" key={`category_${ci}_doc_${di}`}>
                      //   <div className="d-title">
                      //     <h4 className="cont__h4">{ document.name }</h4><img src="/images/right_arrow.svg" alt="arrow-img" className="arrow-img"/></div>
                      // </div>
                      <Fragment key={`doc-block_${ci}_${di}`}>
                      {config.partner === 'tKQgHPEaRHz1bMAlCBzk' 
                        ? (
                          <Link
                            onClick={openDocumentDetail(document)}
                            key={`doc-block_${ci}_${di}`}
                            className="d-title-link-2 w-inline-block"
                            to={`/document/${document.id}`}
                          >
                            <div className="title-wrap-2 d-title">
                              <h4 className="cont__h4-2">{document.name}</h4>
                              {/* {props.shouldPushToSubscription(category.id) ? props.renderPackLabel('initial-position') : null} */}
                              <img
                                src={arrowUpBeci}
                                alt="arrow-img"
                                width="10"
                                className="arrow-img"
                              />
                            </div>
                          </Link>
                        )
                        : (
                          <div
                            onClick={openDocumentDetail(document)}
                            key={`doc-block_${ci}_${di}`}
                            className="d-title-link-2 w-inline-block"
                          >
                            <div className="title-wrap-2 d-title">
                              <h4 className="cont__h4-2">{document.name}</h4>
                              {/* {props.shouldPushToSubscription(category.id) ? props.renderPackLabel('initial-position') : null} */}
                              <img
                                src={"https://uploads-ssl.webflow.com/5e4d1c6f5370e9083c63a2f9/5e53ed45ab798473b34ac1f8_hero-arrow-right.svg"}
                                alt="arrow-img"
                                width="10"
                                className="arrow-img"
                              />
                            </div>
                          </div>
                        )
                      }
                      </Fragment>
                    );
                  })
                ) : (
                  <span>{""}</span>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="gray-sec">
        <div className="gray-sec__cont">
          <h1 className="gray-sec__h1">{t("documents.footer_title")}</h1>
          <div onClick={openContact} className="btn btn--blue w-inline-block">
            <h4 className="btn__h">{t("documents.footer_button")}</h4>
            <div className="growing-div growing-div--blue"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
