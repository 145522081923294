import React, { useContext, useState, Fragment, useRef, useEffect } from 'react';

import VariablesInner from './VariablesInner';
import Input from './UI/Input';
import Radio from './UI/Radio';
import Checkbox from './UI/Checkbox'
import Textarea from './UI/Textarea'
import moment from 'moment'
import Tooltip from './UI/Tooltip';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { areSectionConditionsMet, dateValueFormat } from '../utils';

import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

const DocumentSectionContent = ({ type, content, bulletListItems, variable, variables, data_type, question, options, tooltip, condition, conditions, values, onValuesChange, index, varIndex, repeatableSectionId, repeatableIndex }) => {
  
  const renderHeading1 = () => {
    return (
      <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h1>
            { renderContent() }
          </h1>
        </div>
      </div>
    )
  }

  const renderHeading2 = () => {
    return (
      <div className="document-detail-variables__head">
        <div className="document-detail-variables__head_top">
          <h2>
            { renderContent() }
          </h2>
        </div>
      </div>
    )
  }

  const renderHeading3 = () => {
    return (
      <div className="document-detail-variables__head">
        <h3>
          { renderContent() }
        </h3>
      </div>
    )
  }

  const renderQuestion = (fieldVariable, handle) => {
    let q
    let o
    let v
    let dt
    let t
    let idx
    if (fieldVariable) {
      t = fieldVariable.tooltip
      q = fieldVariable.tooltip
      o = fieldVariable.options || []
      v = fieldVariable.variable
      dt = fieldVariable.type
      idx = fieldVariable.idx
    } else {
      q = question
      o = options || []
      v = variable
      dt = data_type
      t = tooltip
      idx = varIndex
    }
    if(dt === 'select') {

      return <VariablesInner 
        title={q}
        choices={o}
        choicesName={v}
        documentValues={values}
        onValueChange={val => onValuesChange(v, val)}
        tooltip={t}
        variable={v}
        id={v}
        index={idx}
      />;
    } else {

      const val = value(v, handle)

      return (
        <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
          <Tooltip text={t} nowrap />
          <div className="variables__head">
            <div className="variables__head_text">
              <div className="fields-opened">
                <p>{ `${q}:`} </p>
                <div className="select_input_wrapper multiselect" id={v}>
                  { o.map((option, optionIndex) => {
                    return (
                      <div>
                        <Checkbox key={`option_${optionIndex}`} name={option.label} onChange={e => onMultiselectChanged(v, val, option.value, e.target.checked)} checked={val && val.includes(option.value)} />
                        <span style={{ marginLeft: 5 }}>{ option.label }</span>
                      </div>
                    )
                  }) }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const fieldKeyFromHandle = (handle) => {
    return handle.substring(3, handle.length - 1)
  }

  const renderContent = (cntnt = content, vrbls = variables) => {
    if(!cntnt) {
      return null
    }
    const regex = /\u000b/g
    let source = cntnt.replace(regex, '')
    let fieldRegex = /\{d\.([^}]*)\}/g
    let fields = source.match(fieldRegex)
    if(fields?.length > 0) {
      const selectVariables = vrbls ? [...vrbls].filter(v => v.type === 'select').map(v => (`{d.${v.variable}}`)) : [];
      fields.forEach(f => {
        if(selectVariables.includes(f)) {
          if(source.includes(`${f}.`)) {
            source = source.replace(`${f}.`, f);
          }
        }
      });
    }

    let items = []
    for(let i in fields) {
      if(!source) {
        continue
      }
      let components = source.split(fields[i])
      items.push(components[0])
      items.push(fields[i])
      components.splice(0, 1)
      source = components.join(fields[i])
    }
    if(source) {
      items.push(source)
    }
    return items.map((item, i) => {
      if(item.match(fieldRegex)) {
        let field = (fieldKeyFromHandle(item) || '').split(':')[0]
        // input field
        let fieldVariable = variableWithField(field, vrbls)
        if(!fieldVariable) {
          if(isRepeatableBlockHandle(field)) {
            return null
          } else {
            return item
          }
        }
        if(fieldVariable.type === 'string' || fieldVariable.type === 'number') {
          return renderTextInput(fieldVariable, item)
        } else if(fieldVariable.type === 'select') {
          // if(source.endsWith('.')) {
          //   console.log(item, variables);
          // }
          return renderSelectInput(fieldVariable, item)
        } else if(fieldVariable.type === 'date') {
          return renderDateInput(fieldVariable.variable, fieldVariable.idx, item)
        } else if(fieldVariable.type === 'multiselect') {
          return renderQuestion(fieldVariable, item)
        } else if(fieldVariable.type === 'textarea') {
          return renderTextarea(fieldVariable, item)
        }
      } else {
        // console.log('TYPE', type);
        return type.includes('heading') ? <span key={i} className="doc-variable-el" data-id={`section-${index}`}>{item}</span> : item;
      }
    })
  }

  const renderText = () => {
    return (
      <div className="variables__text">
        { renderContent() }
      </div>
    )
  }

  const renderBulletList = () => {
    return (
      <ul className="variables__text">
        { bulletListItems.map(renderBulletListItem)}
      </ul>
    )
  }

  const renderBulletListItem = (item, index) => {
    return (
      <li className="bullet-list-item" style={{ marginLeft: `${1*((item.level + 1) || 1)}rem` }}>
        { renderContent(item.content, item.variables) }
      </li>
    )
  }

  const variableWithField = (handle, vrbls = variables) => {
    let variableName
    if(repeatableSectionId) {
      let components = handle.split('.')
      variableName = components[components.length - 1]
    } else {
      variableName = handle
    }
    for(let i in vrbls) {
      if(vrbls[i].variable === variableName) {
        return vrbls[i]
      }
    }
    return null
  }

  const isRepeatableBlockHandle = (handle) => {
    return ( handle === `${repeatableSectionId}[i]` || handle === `${repeatableSectionId}[i+1]` )
  }

  const onInputChange = (variable, value) => {
    if(repeatableSectionId) {
      let val = duplicatableValuesArray()
      // to enable updating multiple variables at once
      if(typeof variable === 'object' && Array.isArray(variable)) {
        for(let i in variable) {
          val[repeatableIndex][variable[i]] = value[i]
        }
      } else {
        val[repeatableIndex][variable] = value
      }
      onValuesChange(repeatableSectionId, val)
    } else {
      onValuesChange(variable, value)
    }
  }
  

  const value = (variable, handle) => {
    let val
    if(repeatableSectionId && repeatableIndex !== -1) {
      if(handle.includes(`${repeatableSectionId}[i]`)) {
        val = duplicatableValuesArray()[repeatableIndex][variable]
      } else { 
        val = values[variable] // in case variable is inside repeatable block but not repeatable itself
      }
    } else if(!repeatableSectionId) {
      val = values[variable]
    }
    return val
  }

  const isInputDisabled = (variableId) => {
    return false
  }

  const renderTextInput = (fieldVariable, handle) => {
    const { variable, tooltip, type, idx} = fieldVariable
    const val = value(variable, handle)
    return (
      <div className="tooltip-wrapper doc-variable-el" data-id={`${idx}${repeatableSectionId ? `-${repeatableIndex}` : ''}`}>
        <div style={{padding: '2px 0'}}>
          <TextInput value={val} variable={variable} isInputDisabled={isInputDisabled} type={type} onInputChange={onInputChange} tooltip={tooltip} />
        </div>
      </div>
    )
  }

  const renderTextarea = (fieldVariable, handle) => {
    const { variable, tooltip, type, idx} = fieldVariable
    const val = value(variable, handle)
    return (
      <div className="tooltip-wrapper textarea-el doc-variable-el" data-id={idx}>
        <Tooltip text={tooltip} nowrap />
        <div style={{padding: '2px 0'}}>
          <Textarea id={variable} value={val || ''} type={type} editMode onChange={onTextAreaInputChange(variable)} className={val ? 'not-empty' : ''} /> 
        </div>
      </div>
    )
  }

  const onTextAreaInputChange = (variable) => (e) => {
    onInputChange(variable, e.target.value)
  }

  const renderSelectInput = (fieldVariable) => {
    return <VariablesInner 
      title={fieldVariable.tooltip}
      choices={fieldVariable.options}
      choicesName={fieldVariable.variable}
      documentValues={values}
      onValueChange={val => onInputChange(fieldVariable.variable, val)}
      tooltip={fieldVariable.tooltip}
      select={true}
      id={fieldVariable.variable}
      index={fieldVariable.idx}
    />;
  }

  const onMultiselectChanged = (variable, prevState, value, checked) => {
    let nextState = prevState
    if(!nextState) {
      nextState = []
    }
    if(checked && !nextState.includes(value)) {
      nextState.push(value)
    } else if(!checked && nextState.includes(value)) {
      nextState.splice(nextState.indexOf(value), 1)
    }
    onInputChange(variable, nextState)
  }

  const renderDateInput = (variable, idx, handle) => {
    const val = value(variable, handle)
    return (
      <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
        {tooltip && <Tooltip text={tooltip} nowrap />}
        <div className={val ? 'date_picker not-empty' : 'date_picker'}>
          <DatePicker
            // id={variable}
            selected={!val ? null : new Date(moment(val, dateValueFormat).valueOf())}
            // selected={new Date()}
            onChange={date => date ? onInputChange(variable, moment(date.valueOf()).format(dateValueFormat)) : ''}
            peekNextMonth
            dateFormat={'dd/MM/yyyy'}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="fr"
          />
        </div>
      </div>
    )
  }

  const renderSection = () => {
    // console.log("render section", variables, values)
    if(!areSectionConditionsMet({ condition, conditions }, values)) {
      return null
    }
    switch(type) {
      case 'heading1':
        return renderHeading1()
      case 'heading2':
        return renderHeading2()
      case 'heading3':
        return renderHeading3()
      case 'question':
        return renderQuestion()
      case 'text':
        return renderText()
      case 'bullet_list':
        return renderBulletList()
      default:
        return null
    }
  }

  const duplicatableValuesArray = () => {
    if(values[repeatableSectionId]) {
      return values[repeatableSectionId]
    } else {
      return [{}]
    }
  }

  const removeRepetition = () => {
    let vals = values[repeatableSectionId]
    vals.splice(repeatableIndex, 1)
    onValuesChange(repeatableSectionId, vals)
  }

  return (
    <div>
      { renderSection() }
      { repeatableSectionId && duplicatableValuesArray().length > 1 ? (
        <span className="u-cursor--pointer" onClick={removeRepetition}>{ 'Supprimer' }</span>
      ) : null }      
    </div>
  )
}

const TextInput = ({ value, variable, isInputDisabled, onInputChange, type, tooltip }) => {
  const spanEl = useRef();
  let add = 24;

  let textInputClass = 'doc-variable-el__span';
  if(type === 'number') {
    textInputClass += ' doc-variable-el__span--number';
    add = 40;
  }
  
  return (
    <Fragment>
      <span ref={spanEl} className={textInputClass}>{value}</span>
      <Input 
        disabled={isInputDisabled(variable)} 
        id={variable} 
        value={value || ''} 
        type={type} 
        editMode 
        onChange={ (e) => onInputChange(variable, e.target.value) } 
        className={value ? 'not-empty' : ''} 
        tooltip={tooltip} 
        inputStyle={
          value 
          ? { width: spanEl.current?.offsetWidth + add || '140px', maxWidth: '100% !important' } 
          : { maxWidth: '100% !important' }
        }
      /> 
    </Fragment>
  );
}

export default DocumentSectionContent;