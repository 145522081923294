import React, { useState, useContext, useEffect } from 'react' 
import { useParams } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'

import Loader from '../../components/UI/Loader'
import { SupportPageLayout } from '../../components'
import { HelpContext } from '../../context/help'
import * as functions from '../../services/functions'
import { t, currentLanguage } from '../../utils'

const HelpArticle = (props) => {
  const { articles, articlesFetched, selectedCategory, setSelectedCategory } = useContext(HelpContext)
  const { id } = useParams()
  const [article, setArticle] = useState({})
  const [articleDoesNotExist, setArticleDoesNotExist] = useState(false)

  // Update read count when article is opened
  useEffect(() => {
    functions.incrementHelpArticleReadCount(id)
  }, [id])

  // Set article from id
  useEffect(() => {
    if(articlesFetched) {
      if(articles[id]) {
        setArticle(articles[id])
        if(!selectedCategory) {
          setSelectedCategory(articles[id].category)
        }
      }else {
        setArticleDoesNotExist(true)
      }
    }
  }, [id, articles, articlesFetched, selectedCategory, setSelectedCategory])

  return (
    <SupportPageLayout withSidebar showBackButton user={props.user}>
      {!articlesFetched 
        ? <Loader normal small primary />
        : Object.keys(article).length > 0 && 
        <div className="help-article">
          <div className="help-article__head">
            <h2>{article.title[currentLanguage()] || 'Missing translation'}</h2>
          </div>
          <div className="help-article__body">
            <div dangerouslySetInnerHTML={{ __html: article.content[currentLanguage()] ? draftToHtml(article.content[currentLanguage()]) : 'Missing translation' }}></div>
          </div>
        </div>
      }
      {articleDoesNotExist &&
        <div className="support-page__no-data">
          <p>{t('support.article_doesnt_exist', { id })}</p>
        </div>
      }
    </SupportPageLayout>
  )
}

export default HelpArticle