import React, { useState } from 'react' 
import { Message, SentimentVeryDissatisfied, SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedOutlined, SentimentVerySatisfied } from '@material-ui/icons'

import Modal from '../UI/Modal'
import Textarea from '../UI/Textarea'
import Button from '../UI/Button'
import { t } from '../../utils'
import * as functions from '../../services/functions'
import firebase from '../../services/firebase'

const GiveFeedbackModal = ({ onClose, showSnackbar, startLoading, stopLoading }) => {
  const [message, setMessage] = useState('')
  const [selectedRating, setSelectedRating] = useState('')

  // Change rating
  const changeRatingHandler = (e, rating) => {
    e.preventDefault()
    setSelectedRating(rating)
  }

  // Send feedback
  const submitHandler = async (e) => {
    e.preventDefault()

    startLoading('give_feedback')
    let content = null
    if(message.trim() !== '') {
      content = message.split('\n').map((text) => `<p>${text ? text : '&nbsp;'}</p>`).join('')
    }

    // email html template
    let emailTop = `
      <div style="background-color: #2663EB; color: #ffffff; margin-bottom: 20px; padding: 10px; font-size: 16px;">Rating: <span style="font-weight: bold;">${selectedRating}</span></div>
    `
    let emailHtml = emailTop

    if(content) {
      emailHtml += `
        <div>${content}</div>
      `
    }

    const { email } = firebase.auth().currentUser

    try {
      await functions.giveFeedback({ from: email, emailHtml, subject: 'Beci - User Feedback' })
    } catch (err) {
      console.log(err)
    }
    stopLoading('give_feedback')
    showSnackbar({ text: t('support.feedback_sent'), color: 'success' })
    onClose()
  }

  return (
    <Modal onClose={onClose} medium noPadding>
      <div className="report-a-bug">
        <div className="report-a-bug__head">
          <h3><Message /> {t('support.send_feedback')}</h3>
        </div>
        <div className="report-a-bug__body">
          <p>{t('support.how_would_you_rate_app')}</p>
          <form onSubmit={submitHandler}>
            <div className="emojis">
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'terrible')} className={selectedRating === 'terrible' ? 'active' : ''}>
                <SentimentVeryDissatisfied />
                <span>{t('support.terrible')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'bad')} className={selectedRating === 'bad' ? 'active' : ''}>
                <SentimentDissatisfied />
                <span>{t('support.bad')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'okay')} className={selectedRating === 'okay' ? 'active' : ''}>
                <SentimentSatisfied />
                <span>{t('support.okay')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'great')} className={selectedRating === 'great' ? 'active' : ''}>
                <SentimentSatisfiedOutlined />
                <span>{t('support.great')}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'excellent')} className={selectedRating === 'excellent' ? 'active' : ''}>
                <SentimentVerySatisfied />
                <span>{t('support.excellent')}</span>
              </a>
            </div>
            <Textarea value={message} onChange={(e) => setMessage(e.target.value)} formEl placeholder={t('support.leave_us_message')} />
            <div className="btn-wrapper">
              <Button type="submit" text={t('support.send_feedback')} primary medium disabled={selectedRating === ''} />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default GiveFeedbackModal