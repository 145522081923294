import React from 'react'

import { t } from '../utils'

const TermsOfUse = () => {
  let sec6para12 = t('terms_page.sec_6_para_12', { link: '<a href="https://www.cfm-fbc.be/fr">https://www.cfm-fbc.be/fr</a>'})

  return (
    <div className="sec">
      <div className="container">
        <div className="terms-page">
          <h1>{t('terms_page.title')}</h1>
          <p className="subtitle">{t('terms_page.subtitle')}</p>
          <p>{t('terms_page.para_1')}</p>

          <section>
            <h2 className="italic">{t('terms_page.sec_1_head_1')}</h2>
            <p>{t('terms_page.sec_1_para_1')}</p>
            <p>{t('terms_page.sec_1_para_2')}</p>
            <p>{t('terms_page.sec_1_para_3')}</p>
            <p>{t('terms_page.sec_1_para_4')}</p>
            <p>{t('terms_page.sec_1_para_5')}</p>
            <p>{t('terms_page.sec_1_para_6')}</p>
            <p>{t('terms_page.sec_1_para_7')}</p>
            <p>{t('terms_page.sec_1_para_8')}</p>
          </section>

          <section>
            <h2>{t('terms_page.sec_2_head_1')}</h2>
            <p>{t('terms_page.sec_2_para_1')}</p>
            <p>{t('terms_page.sec_2_para_2')}</p>
            <p>{t('terms_page.sec_2_para_3')}</p>
            <ul className="list">
              <li>{t('terms_page.sec_2_litem_1')}</li>
              <li>{t('terms_page.sec_2_litem_2')}</li>
              <li>{t('terms_page.sec_2_litem_3')}
                <ol className="sub-list">
                  <li>{t('terms_page.sec_2_slitem_1')}</li>
                  <li>{t('terms_page.sec_2_slitem_2')}</li>
                </ol>
              </li>
              <li>{t('terms_page.sec_2_litem_4')}</li>
              <li>{t('terms_page.sec_2_litem_5')}</li>
              <li>{t('terms_page.sec_2_litem_6')}</li>
              <li>{t('terms_page.sec_2_litem_7')}</li>
              <li>{t('terms_page.sec_2_litem_8')}</li>
              <li>{t('terms_page.sec_2_litem_9')}</li>
              <li>{t('terms_page.sec_2_litem_10')}</li>
              <li>{t('terms_page.sec_2_litem_11')}</li>
              <li>{t('terms_page.sec_2_litem_12')}</li>
            </ul>
            <p>{t('terms_page.sec_2_para_4')}</p>
            <p>{t('terms_page.sec_2_para_5')}</p>
            <p>{t('terms_page.sec_2_para_6')}</p>
            <p>{t('terms_page.sec_2_para_7')}</p>
            <p>{t('terms_page.sec_2_para_8')}</p>
            <p>{t('terms_page.sec_2_para_9')}</p>
          </section>

          <section>
            <h2>{t('terms_page.sec_3_head_1')}</h2>
            <p>{t('terms_page.sec_3_para_1')}</p>
            <p>{t('terms_page.sec_3_para_2')}</p>
            <p>{t('terms_page.sec_3_para_3')}</p>
            <ul className="list">
              <li>{t('terms_page.sec_3_litem_1')}</li>
              <li>{t('terms_page.sec_3_litem_2')}</li>
              <li>{t('terms_page.sec_3_litem_3')}</li>
              <li>{t('terms_page.sec_3_litem_4')}</li>
              <li>{t('terms_page.sec_3_litem_5')}</li>
            </ul>
          </section>

          <section>
            <h2>{t('terms_page.sec_4_head_1')}</h2>
            <p>{t('terms_page.sec_4_para_1')}</p>
            <p>{t('terms_page.sec_4_para_2')}</p>
            <p>{t('terms_page.sec_4_para_3')}</p>
            <p>{t('terms_page.sec_4_para_4')}</p>
            <p>{t('terms_page.sec_4_para_5')}</p>
            <p>{t('terms_page.sec_4_para_6')}</p>
          </section>

          <section>
            <h2>{t('terms_page.sec_5_head_1')}</h2>
            <h3 className="italic">{t('terms_page.sec_5_subhead_1')}</h3>
            <p>{t('terms_page.sec_5_para_1')}</p>
            <p>{t('terms_page.sec_5_para_2')}</p>
            <p>{t('terms_page.sec_5_para_3')}</p>
            <p>{t('terms_page.sec_5_para_4')}</p>
            <h3 className="italic">{t('terms_page.sec_5_subhead_2')}</h3>
            <p>{t('terms_page.sec_5_para_5')}</p>
            <p>{t('terms_page.sec_5_para_6')}</p>
            <p>{t('terms_page.sec_5_para_7')}</p>
            <p>{t('terms_page.sec_5_para_8')}</p>
            <p>{t('terms_page.sec_5_para_9')}</p>
            <p>{t('terms_page.sec_5_para_10')}</p>
            <p>{t('terms_page.sec_5_para_11')}</p>
            <p>{t('terms_page.sec_5_para_12')}</p>
            <ul className="list">
              <li>{t('terms_page.sec_5_litem_1')}</li>
              <li>{t('terms_page.sec_5_litem_2')}</li>
              <li>{t('terms_page.sec_5_litem_3')}</li>
              <li>{t('terms_page.sec_5_litem_4')}</li>
              <li>{t('terms_page.sec_5_litem_5')}</li>
            </ul>
            <p>{t('terms_page.sec_5_para_13')}</p>
            <ul className="list">
              <li>{t('terms_page.sec_5_litem_6')}</li>
              <li>{t('terms_page.sec_5_litem_7')}</li>
              <li>{t('terms_page.sec_5_litem_8')}</li>
              <li>{t('terms_page.sec_5_litem_9')}</li>
            </ul>
            <p>{t('terms_page.sec_5_para_14')}</p>
            <p>{t('terms_page.sec_5_para_15')}</p>
            <p>{t('terms_page.sec_5_para_16')}</p>
          </section>

          <section>
            <h2>{t('terms_page.sec_6_head_1')}</h2>
            <h3>{t('terms_page.sec_6_subhead_1')}</h3>
            <p>{t('terms_page.sec_6_para_1')}</p>
            <p>{t('terms_page.sec_6_para_2')}</p>
            <ul>
              <li>{t('terms_page.sec_6_litem_1')}</li>
              <li>{t('terms_page.sec_6_litem_2')}</li>
              <li>{t('terms_page.sec_6_litem_3')}</li>
              <li>{t('terms_page.sec_6_litem_4')}</li>
              <li>{t('terms_page.sec_6_litem_5')}</li>
              <li>{t('terms_page.sec_6_litem_6')}</li>
              <li>{t('terms_page.sec_6_litem_7')}</li>
              <li>{t('terms_page.sec_6_litem_8')}</li>
              <li>{t('terms_page.sec_6_litem_9')}</li>
              <li>{t('terms_page.sec_6_litem_10')}</li>
              <li>{t('terms_page.sec_6_litem_11')}</li>
              <li>{t('terms_page.sec_6_litem_12')}</li>
              <li>{t('terms_page.sec_6_litem_13')}</li>
              <li>{t('terms_page.sec_6_litem_14')}</li>
              <li>{t('terms_page.sec_6_litem_15')}</li>
              <li>{t('terms_page.sec_6_litem_16')}</li>
            </ul>
            <p>{t('terms_page.sec_6_para_3')}</p>
            <h3>{t('terms_page.sec_6_subhead_2')}</h3>
            <p>{t('terms_page.sec_6_para_4')}</p>
            <p>{t('terms_page.sec_6_para_5')}</p>
            <h3>{t('terms_page.sec_6_subhead_3')}</h3>
            <p>{t('terms_page.sec_6_para_6')}</p>
            <h3>{t('terms_page.sec_6_subhead_4')}</h3>
            <p>{t('terms_page.sec_6_para_7')}</p>
            <p>{t('terms_page.sec_6_para_8')}</p>
            <p>{t('terms_page.sec_6_para_9')}</p>
            <h3>{t('terms_page.sec_6_subhead_5')}</h3>
            <p>{t('terms_page.sec_6_para_10')}</p>
            <p>{t('terms_page.sec_6_para_11')}</p>
            <p dangerouslySetInnerHTML={{ __html: sec6para12 }}></p>
            <p>{t('terms_page.sec_6_para_13')}</p>
            <p>{t('terms_page.sec_6_para_14')}</p>
            <p>{t('terms_page.sec_6_para_15')}</p>
          </section>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse