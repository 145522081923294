import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({ choices, name, onChange, selectedValue, error, style2 }) => {
  return(
    <div className={style2 ? "form-radio form-radio--2" : "form-radio"}>
      <div className="form-radio__choices">
        {choices.map((choice, index) => (
          <div key={index}>
            <label className="form-radio__choices_label">
              <input 
                type="radio" 
                name={name} 
                onChange={onChange}
                value={choice.value}
                // id={choice.id}
                checked={selectedValue && selectedValue === choice.value}
              />
              <div className="form-radio__choices_btn"></div>
              <p>{choice.label}</p>
            </label>
          </div>
        ))}
      </div>
      {error && <div className="form-radio__error">{error}</div>}
    </div>
  );
}

Radio.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  error: PropTypes.string
};

export default Radio;