import { ChevronLeft, DescriptionOutlined } from '@material-ui/icons'

import CustomTooltip from "./UI/CustomTooltip"

const SidePanel = ({ children, onTriggerClick = () => {}, isOpened }) => {
  return (
    <div className="side-panel">
      <div className="side-panel__menu">
        <CustomTooltip content="Masquer" position="left">
          <div className="trigger" onClick={onTriggerClick}>
            <ChevronLeft />
          </div>
        </CustomTooltip>

        <ul>
          <li>
            <div className="tooltip-wrapper">
              <CustomTooltip content="Variables" position="left">
                <div>
                  <a 
                    href="/"
                    onClick={(e) => {
                      e.preventDefault()
                      if(!isOpened) { onTriggerClick() }
                    }} 
                    className={'active'}
                  >
                    <DescriptionOutlined />
                  </a>
                </div>
              </CustomTooltip>
            </div>
          </li>
        </ul>

      </div>

      <div className="side-panel__main">
        { children }
      </div>
    </div>
  )
}

export default SidePanel