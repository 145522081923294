import React, { createContext, useReducer, useState } from 'react'

import * as functions from '../services/functions'
import { GET_HELP_ARTICLES, GET_HELP_CATEGORIES } from './types'
import { sortArrayOfObjects } from '../utils'

import reducer from './helpReducer'

const HelpContext = createContext()

const HelpProvider = ({ children }) => {
  const initialState = {
    articles: {},
    articlesArr: [],
    whatsNewArticles: [],
    documentationArticles: [],
    articlesFetched: false,
    categories: {},
    categoriesArr: [],
    categoriesFetched: false,
    features: [],
    featuresFetched: false
  }

  const [selectedCategory, setSelectedCategory] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  // Fetch articles
  const fetchHelpArticles = async (partner) => {
    try {
      const res = await functions.fetchHelpArticles({ partner })
      const data = res.data
      let arr = []
      let whatsNewArr = []
      let documentationArr = []
      for(let id in data) {
        if(data[id].category === 'whats_new') {
          whatsNewArr.push({ id, ...data[id] })
        }else if(data[id].category === 'documentation') {
          documentationArr.push({ id, ...data[id] })
        }else {
          arr.push({ id, ...data[id] })
        }
      }
      dispatch({
        type: GET_HELP_ARTICLES,
        payload: {
          obj: data,
          arr: sortArrayOfObjects(arr, 'title.en', 'desc'),
          whatsNewArr: sortArrayOfObjects(whatsNewArr, 'title.en', 'desc'),
          documentationArr: sortArrayOfObjects(documentationArr, 'title.en', 'desc')
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  // Fetch help categories
  const fetchHelpCategories = async () => {
    try {
      const res = await functions.fetchHelpCategories()
      const data = res.data
      let arr = []
      for(let id in data) {
        if(id !== 'whats_new' && id !== 'documentation') {
          arr.push({ id, ...data[id] })
        }
      }
      dispatch({
        type: GET_HELP_CATEGORIES,
        payload: {
          obj: data,
          arr
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  return <HelpContext.Provider value={{
    articles: state.articles,
    articlesArr: state.articlesArr,
    whatsNewArticles: state.whatsNewArticles,
    documentationArticles: state.documentationArticles,
    articlesFetched: state.articlesFetched,
    categories: state.categories,
    categoriesArr: state.categoriesArr,
    categoriesFetched: state.categoriesFetched,
    fetchHelpArticles,
    fetchHelpCategories,
    selectedCategory,
    setSelectedCategory,
  }}>
    { children }
  </HelpContext.Provider>
}

export { HelpContext, HelpProvider }