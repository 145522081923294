import React, { useContext } from 'react'

import { Auth } from '../components'

import { ReactGA } from '../utils'
import { GlobalContext } from '../context/global'

const Login = (props) => {

  const { startLoading, stopLoading, showSnackbar } = props
  const { redirectTo, setRedirectTo } = useContext(GlobalContext)

  const onLogin = () => {
    if(redirectTo) {
      setRedirectTo(null)
      navigate(redirectTo)
      return
    }
    navigate('/')
    ReactGA.event({
      category: 'auth',
      action: 'User signed in',
      label: 'Connect'
    })
  }

  const onRegister = () => {
    const { history } = props
    if(redirectTo) {
      setRedirectTo(null)
      navigate(redirectTo)
      return
    }
    history.push({
      pathname: 'myprofile',
      state: {
        fromRegister: true
      }
    })
    ReactGA.event({
      category: 'auth',
      action: 'User registered',
      label: 'Connect'
    })
  }

  const onPasswordReset = () => {
  }

  const navigate = (path) => {
    const { history } = props
    history.push(path)
  }

  return (
    <div className="sec" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <div className="container">
        <Auth
          startLoading={startLoading}
          stopLoading={stopLoading}
          showSnackbar={showSnackbar}
          onLogin={onLogin}
          onPasswordReset={onPasswordReset}
          onRegister={onRegister}
          getUser={props.getUser}
          updateUserType={props.updateUserType}
          navigate={navigate}
        />
      </div>
    </div>
  )
}

export default Login
