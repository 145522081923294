import { userId, userIdToken } from './auth'

import config from '../config.json'

import moment from 'moment'
import fetch from 'node-fetch'

const ENV = config.env
const ENVIRONMENTS_COL = 'environments'
const CATEGORIES_COL = 'categories'
const USERS_COL = 'users'
const TEMPLATES_COL = config.uses_v2 ? 'documents_v2' : 'documents'
const USER_DOCUMENTS_COL = 'documents'
const USER_SIGNED_DOCUMENTS_COL = 'signed_documents'
const DRAFTS_COL = 'drafts'
const FOLDERS_COL = 'folders'
const CONTACT_COL = 'contact_forms'
const VERIFICATION_CODE_COL = 'verifications_codes'
const SUBSCRIPTION_COL = 'subscriptions'
const FUNCTIONS_BASE_URL = 'https://us-central1-lawstud-io.cloudfunctions.net'
// const FUNCTIONS_BASE_URL = 'http://localhost:5000/lawstud-io/us-central1'

// --MARK-- DATABASE INTERACTION

const GET_COL_ACTION = 'getCollection'
const GET_DOC_ACTION = 'getDocument'
const CREATE_ACTION = 'createDocument'
const CREATE_SPECIFIC_ACTION = 'createSpecificDocument'
const UPDATE_ACTION = 'updateDocument'
const DELETE_ACTION = 'deleteDocument'

async function getCollection(path, queryParams) {
  let idToken = await userIdToken()
  let body = {
    action: GET_COL_ACTION,
    path,
    idToken,
    queryParams
  }
  let result = await post(`${FUNCTIONS_BASE_URL}/api`, body)
  return result
}

async function getDocument(path) {
  let idToken = await userIdToken()
  let body = {
    action: GET_DOC_ACTION,
    path,
    idToken
  }
  let result = await post(`${FUNCTIONS_BASE_URL}/api`, body)
  return result
}


async function createSpecificDocument(path, data) {
  let idToken = await userIdToken()
  const timestamp = moment().valueOf()
  data.updated = timestamp
  data.created = timestamp
  let body = {
    action: CREATE_SPECIFIC_ACTION,
    path,
    idToken,
    data
  }
  let result = await post(`${FUNCTIONS_BASE_URL}/api`, body)
  return result
}

async function createDocument(path, data) {
  let idToken = await userIdToken()
  const timestamp = moment().valueOf()
  data.updated = timestamp
  data.created = timestamp
  let body = {
    action: CREATE_ACTION,
    path,
    idToken,
    data
  }
  let result = await post(`${FUNCTIONS_BASE_URL}/api`, body)
  return result
}

async function updateDocument(path, data) {
  let idToken = await userIdToken()
  const timestamp = moment().valueOf()
  data.updated = timestamp
  let body = {
    action: UPDATE_ACTION,
    path,
    idToken,
    data
  }
  let result = await post(`${FUNCTIONS_BASE_URL}/api`, body)
  return result
}

async function deleteDocument(path) {
  let idToken = await userIdToken()
  let body = {
    action: DELETE_ACTION,
    path,
    idToken
  }
  let result = await post(`${FUNCTIONS_BASE_URL}/api`, body)
  return result
}

// --MARK-- API COMMUNICATION

function post(url, body) {
  return new Promise(resolve => {
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => res.json())
      .then(body => {
        resolve(body)
      }).catch(err => {
        console.log('post error', err)
      })
  })
}

// --MARK-- DOCUMENTS

async function getDocuments() {
  return await getCollection(`${ENVIRONMENTS_COL}.${ENV}.${TEMPLATES_COL}`, { 'meta.partner': config.partner })
}

// --MARK-- CATEGORIES

async function getCategories(userType) {
  let query = { 'meta.partner': config.partner }
  if(userType) {
    query['meta.type'] = userType
  }
  return await getCollection(`${ENVIRONMENTS_COL}.${ENV}.${CATEGORIES_COL}`, query)
}

// --MARK-- USER DATA

async function getUser() {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  return await getDocument(`${USERS_COL}.${uid}`)
}

async function getUserDocuments() {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  return await getCollection(`${USERS_COL}.${uid}.${USER_DOCUMENTS_COL}`, { environment: config.env })
}

async function getUserSignedDocuments() {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  return await getCollection(`${USERS_COL}.${uid}.${USER_SIGNED_DOCUMENTS_COL}`)
}

async function getUserDrafts() {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  return await getCollection(`${USERS_COL}.${uid}.${DRAFTS_COL}`, { environment: config.env })
}

async function getUserFolders() {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  return await getCollection(`${USERS_COL}.${uid}.${FOLDERS_COL}`, { environment: config.env })
}

function listenForUserDocument(documentId, listener) {
  let uid = userId()
  if (!uid) {
    return null
  }
  let path = `${USERS_COL}.${uid}.${USER_DOCUMENTS_COL}.${documentId}`
  return setInterval(async () => {
    let result = await getDocument(path)
    listener(result)
  }, 1500)
}

async function updateUserDocument(document, id) {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  let path = `${USERS_COL}.${uid}.${USER_DOCUMENTS_COL}`
  if (!!document) {
    document.environment = config.env
    if (!!id) {
      path = `${path}.${id}`
      return await updateDocument(path, document)
    } else {
      return await createDocument(path, document)
    }
  } else {
    path = `${path}.${id}`
    return await deleteDocument(path)
  }
}

async function updateUserDraft(draft, id) {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  let path = `${USERS_COL}.${uid}.${DRAFTS_COL}`
  if (!!draft) {
    draft.environment = config.env
    if (!!id) {
      path = `${path}.${id}`
      return await updateDocument(path, draft)
    } else {
      return await createDocument(path, draft)
    }
  } else {
    path = `${path}.${id}`
    return await deleteDocument(path)
  }
}

async function updateUserFolder(folder, id) {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  let path = `${USERS_COL}.${uid}.${FOLDERS_COL}`
  if (!!folder) {
    folder.environment = config.env
    if (!!id) {
      path = `${path}.${id}`
      return await updateDocument(path, folder)
    } else {
      let result = await createDocument(path, folder)
      return result
    }
  } else {
    path = `${path}.${id}`
    return await deleteDocument(path)
  }
}

async function createUser(user) {
  const uid = userId()
  const path = `${USERS_COL}.${uid}`
  return await createSpecificDocument(path, user)
}

async function updateUser(user) {
  const uid = userId()
  if (!uid) {
    return { error: { message: 'User not authenticated' } }
  }
  const path = `${USERS_COL}.${uid}`
  return await updateDocument(path, user)
}

async function sendConactForm(formData) {
  let path = `${ENVIRONMENTS_COL}.${ENV}.${CONTACT_COL}`
  return await createDocument(path, formData)
}

async function getSubscriptions() {
  return await getCollection(SUBSCRIPTION_COL)
}

export {
  updateUserDocument,
  updateUserFolder,
  updateUserDraft,
  createUser,
  updateUser,
  sendConactForm,

  getCategories,
  getDocuments,
  getUser,
  getUserDocuments,
  getUserSignedDocuments,
  listenForUserDocument,
  getUserFolders,
  getUserDrafts,
  getSubscriptions
};
