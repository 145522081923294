import React, { useState, useRef } from 'react'
import { BugReport, CloudUpload } from '@material-ui/icons'

import Modal from '../UI/Modal'
import Textarea from '../UI/Textarea'
import Input from '../UI/Input'
import Button from '../UI/Button'
import { t, validateURL, getFileData, base64toBlob, blobToFile } from '../../utils'
import * as functions from '../../services/functions'
import firebase from '../../services/firebase'

const ReportABugModal = ({ onClose, user, startLoading, stopLoading, showSnackbar }) => {
  const [bugMsg, setBugMsg] = useState('')
  const [uploadFromView, setUploadFromView] = useState('computer')
  const [url, setUrl] = useState('')
  const [files, setFiles] = useState([])
  const fileEl = useRef()
  const dragAndDropEl = useRef()

  // Change view
  const changeViewHandler = (e, view) => {
    e.preventDefault()
    setUploadFromView(view)
    setFiles([])
    setUrl('')
  }

  // On file input change
  const fileChangeHandler = (e) => {
    const files = e.target.files 
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
    let error = false
    Array.from(files).forEach(file => {
      if(!allowedTypes.includes(file.type)) {
        error = true
      }
    })
    if(error) {
      return showSnackbar({ text: t('notification.invalid_file_type'), color: 'error' })
    }
    setFiles(Array.from(files))
  }

  // On upload image button click
  const uploadImgBtnClickHandler = () => {
    if(fileEl.current) {
      fileEl.current.click()
    }
  }

  // On drag enter
  const dragEnterHandler = (e) => {
    e.preventDefault()
  }
  
  // On drag leave
  const dragLeaveHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.remove('mouse-over')
  }
  
  // On drag over
  const dragOverHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.add('mouse-over')
 
  }

  // On drop
  const dropHandler = (e) => {
    e.preventDefault()
    dragAndDropEl.current.classList.remove('mouse-over')
    const files = e.dataTransfer.files
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
    let error = false
    Array.from(files).forEach(file => {
      if(!allowedTypes.includes(file.type)) {
        error = true
      }
    })
    if(error) {
      return showSnackbar({ text: t('notification.invalid_file_type'), color: 'error' })
    }
    setFiles(Array.from(files))
  }

  // On submit
  const submitHandler = async (e) => {
    e.preventDefault()

    // validate url if upload view is url
    if(uploadFromView === 'url' && url.trim() !== '' && !validateURL(url.trim())) {
      return showSnackbar({ text: t('notification.invalid_url'), color: 'error' })
    }

    let data = []

    startLoading('bug_report')
    if(url.trim() !== '') {
      const res = await functions.getFile({ url, getType: true })
      if(!res.success) {
        setUrl('')
        if(res.type === 'file-too-big') {
          return showSnackbar({ text: t('notification.file_too_big'), color: 'error' })
        }
        return showSnackbar({ text: t('notification.something_went_wrong'), color: 'error' })
      }

      const blob = base64toBlob(res.data)
      let fileName = `file-${Date.now()}`
      let fileType = ''
      let fileExt = ''
      if(res.file_type) {
        fileExt = res.file_type.ext
        fileName = `${fileName}.${fileExt}`
        fileType = res.file_type.mime
      }else {
        return showSnackbar({ text: t('notification.no_file_extension'), color: 'error' })
      }

      const allowedTypes = ['png', 'jpeg', 'jpg', 'gif']
      if(!allowedTypes.includes(fileExt)) {
        return showSnackbar({ text: t('notification.invalid_file_type'), color: 'error' })
      }

      const file = blobToFile(blob, fileName)
      const fileData = await getFileData(file, fileType)
      data = [fileData]
    }else {
      // promises array for files
      let filesData = []
      files.forEach(file => filesData.push(getFileData(file)))
      data = await Promise.all(filesData)
    }

    let content = bugMsg.split('\n').map((text) => `<p>${text ? text : '&nbsp;'}</p>`).join('')

    // email html template
    let emailHtml = `
      <div>${content}</div>
    `

    const { email } = firebase.auth().currentUser

    try {
      await functions.reportBug({ from: email, emailHtml, subject: 'Beci Bug Report', files: data.length > 0 ? data : [] })
    } catch (err) {
      console.log(err)
    }
    stopLoading('bug_report')
    showSnackbar({ text: t('notification.message_sent'), color: 'success' })
    onClose()
  }

  return (
    <Modal onClose={onClose} medium noPadding>
      <div className="report-a-bug">
        <div className="report-a-bug__head">
          <h3><BugReport /> {t('support.report_bug')}</h3>
        </div>
        <div className="report-a-bug__body">
          <form onSubmit={submitHandler}>
            <Textarea label={t('support.describe_bug')} value={bugMsg} onChange={(e) => setBugMsg(e.target.value)} formEl />
            <div className="image-wrapper">
              <div className="image-wrapper__left">
                <p>{t('support.insert_image_from')}</p>
                <ul>
                  <li>
                    <a href="/#" className={uploadFromView === 'computer' ? 'active' : ''} onClick={(e) => changeViewHandler(e, 'computer')}>
                      {t('support.my_computer')}
                    </a>
                  </li>
                  <li>
                    <a href="/#" className={uploadFromView === 'url' ? 'active' : ''} onClick={(e) => changeViewHandler(e, 'url')}>
                      {t('support.from_url')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="image-wrapper__right">
                {uploadFromView === 'computer'
                  ?
                  <div 
                    className="image-wrapper__right_dnd" 
                    ref={dragAndDropEl}
                    onDragEnter={dragEnterHandler}
                    onDragLeave={dragLeaveHandler}
                    onDragOver={dragOverHandler}
                    onDrop={dropHandler}
                  >
                    <input type="file" className="hidden" onChange={fileChangeHandler} multiple ref={fileEl} />
                    <Button text={t('support.upload_images')} icon={<CloudUpload />} onButtonClick={uploadImgBtnClickHandler} primary />
                    <p>{t('support.or_dnd_image')} {files.length > 0 && `(${files.length} ${t('support.images_selected')})`}</p>
                    {files.length > 0 && <p><a href="/#" onClick={(e) => { e.preventDefault(); setFiles([])}}>{t('support.remove_images')}</a></p>}
                  </div>
                  :
                  <div className="image-wrapper__right_url">
                    <Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder={t('support.insert_url_here')} whiteBackground />
                  </div>
                }
              </div>
            </div>
              
            <div className="btn-wrapper">
              <Button type="submit" text={t('support.send_bug_report')} primary medium disabled={bugMsg.trim() === ''} />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
} 

export default ReportABugModal