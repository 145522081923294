import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Loader from '../../components/UI/Loader'
import { SupportPageLayout, SearchForm, MostPopularArticles } from '../../components'
import { HelpContext } from '../../context/help'
import { t, currentLanguage } from '../../utils'

const HelpPage = (props) => {
  const { articlesFetched, articlesArr, categoriesFetched, categoriesArr, setSelectedCategory } = useContext(HelpContext)
  const [showCategories, setShowCategories] = useState(false)
  const [categoriesWithNumOfArticles, setCategoriesWithNumOfArticles] = useState([])
  const history = useHistory()

  // Show categories if articles and categories are fetched and add numberOfArticles property to each category
  useEffect(() => {
    if(articlesFetched && categoriesFetched && !showCategories) {
      let articlesByCategory = {}
      articlesArr.forEach((article) => {
        if(articlesByCategory[article.category]) {
          articlesByCategory[article.category] += 1
        }else {
          articlesByCategory[article.category] = 1
        }
      })
      let newCategoriesArr = categoriesArr.map((category) => {
        category.numberOfArticles = articlesByCategory[category.id] || 0
        return category
      })
      setCategoriesWithNumOfArticles(newCategoriesArr)
      setShowCategories(true)
    }
  }, [articlesFetched, categoriesFetched, articlesArr, categoriesArr, showCategories])

  // Category click handler
  const categoryClickHandler = (id) => {
    history.push(`/help-category/${id}`)
    setSelectedCategory(id)
  }

  return (
    <SupportPageLayout user={props.user}>
      <div className="help-search">
        <SearchForm />
      </div>
      <MostPopularArticles articles={articlesArr} />
      {!articlesFetched && !categoriesFetched && <div className="loader-wrapper loader-wrapper--2"><Loader normal small primary /></div>}
      <div className="help-categories">
        {showCategories && categoriesWithNumOfArticles.map((category, idx) => {
          if(category.id !== 'whats_new') {
            return (
              <div className="help-categories__box" key={idx} onClick={() => categoryClickHandler(category.id)}>
                <h3>{category.title[currentLanguage()] || 'Missing translation'}</h3>
                <p>{category.numberOfArticles} {category.numberOfArticles === 1 ? t('support.article') : t('support.articles')}</p>
              </div>
            )
          }
          return null
        })}
      </div>
    </SupportPageLayout>
  )
}

export default HelpPage