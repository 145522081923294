import React from 'react';

const Tooltip = ({ position = 'top', text, small, nowrap }) => {
  let tooltipClass = 'tooltip';

  if(position === 'top') {
    tooltipClass += ' tooltip--top';
  }
  if(position === 'left') {
    tooltipClass += ' tooltip--left';
  }
  if(position === 'bottom') {
    tooltipClass += ' tooltip--bottom';
  }
  if(position === 'right') {
    tooltipClass += ' tooltip--right';
  }

  if(small) {
    tooltipClass += ' tooltip--small';
  }
  if(nowrap) {
    tooltipClass += ' tooltip--nowrap';
  }

  return(
    <div className={tooltipClass}><div>{text}</div></div>
  );
}

export default Tooltip;