import { createContext, useState } from "react"

const GlobalContext = createContext()

const GlobalProvider = ({ children }) => {
  const [sidePanelHeight, setSidePanelHeight] = useState('100%')
  const [redirectTo, setRedirectTo] = useState(null)
  const [afterRegistration, setAfterRegistration] = useState(false)

  return (
    <GlobalContext.Provider
      value={{
        sidePanelHeight,
        setSidePanelHeight,
        redirectTo,
        setRedirectTo,
        afterRegistration,
        setAfterRegistration,
      }}
    >
      { children }
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }