import React from 'react';
import { areSectionConditionsMet } from '../utils';

import DocumentSectionContent from './DocumentSectionContent';

const DocumentSection = ({ type, content, items, variable, variables, data_type, question, options, tooltip, condition, conditions, values, onValuesChange, index, varIndex, repeatableSectionId }) => {
  
  let classes = 'variables opened'

  const duplicateSection = () => {
    let vals = values[repeatableSectionId]
    if(!vals) {
      vals = [{}]
    }
    
    vals.push({})
    onValuesChange(repeatableSectionId, vals)
  }

  const duplicatableValuesArray = () => {
    if(values[repeatableSectionId]) {
      return values[repeatableSectionId]
    } else {
      return [{}]
    }
  }

  const variablesContainOnlyCustomer = () => {
    return (variables && variables.length === 1 && variables[0].variable === 'co_customers_id')
  }

  return !areSectionConditionsMet({ condition, conditions }, values) ? null : (
    <div className={classes} data-index={`section-${index}`} syle={{ whiteSpace: 'pre-line' }}>
      <div className="variables__body">
        { Boolean(repeatableSectionId) ? ( 
          <div>
            <span className="u-cursor--pointer" onClick={duplicateSection}>{ variablesContainOnlyCustomer() ? 'Ajouter un contact' : 'Dupliquer' }</span>
            { duplicatableValuesArray().map((item, ri) => {
              return (
                <DocumentSectionContent key={`repeatable_content_${repeatableSectionId}_${ri}`} values={values} onValuesChange={onValuesChange} type={type} content={content} bulletListItems={items} question={question} variable={variable} variables={variables} data_type={data_type} options={options} condition={condition} conditions={conditions} tooltip={tooltip} index={index} varIndex={varIndex} repeatableSectionId={repeatableSectionId} repeatableIndex={ri} />
              )
            })}
          </div>
        ) : (
          <DocumentSectionContent values={values} onValuesChange={onValuesChange} type={type} content={content} bulletListItems={items} question={question} variable={variable} variables={variables} data_type={data_type} options={options} condition={condition} conditions={conditions} tooltip={tooltip} index={index} varIndex={varIndex} />
        ) }
      </div>
    </div>
  );
}

export default DocumentSection;