import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { sortedArrayFromObject, idFromName, t, currentLanguage } from '../utils'

import { CategorySkeleton, PartnersFooter } from '../components'

import config from '../config.json'

const SingleDocHome = (props) => {

  const [category, setCategory] = useState(null)
  const [document, setDocument] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    props.refreshInteractions()
  })
  
  useEffect(() => {
    setLoading(true)
    let categoriesArray = sortedArrayFromObject(props.categories)
    if(categoriesArray.length > 0) {
      setCategory(categoriesArray[0])
    }
    let documentsArray = sortedArrayFromObject(props.documents)
    if(documentsArray.length > 0) {
      setDocument(documentsArray[0])
    }
    if(categoriesArray.length > 0) {
      setLoading(false)
    }
  }, [props.categories, props.documents])


  const openDocument = () => {
    if(!document) {
      return
    }
    const { history } = props
    history.push(`/document-form/${document.id}`)   
  }

  const renderCategory = () => {
    return (
      <div className="first-row">
        <div className="col col--hero">
          <div onClick={openDocument} className="hero-link w-inline-block">
            <div className="hero-cont">
              <div className="hero-img-wrap" style={{ backgroundImage: `url(${category.image})` }}></div>
              <div className="title-wrap centered">
                <h4 className="cont__h4">{ t('home.create_contract') }</h4></div>
              <div className="icon-wrap"><img src={category.icon} alt="category-icon" className="hero-icon"/></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderSkeleton = () => {
    return (
      <div className="first-row">
        <CategorySkeleton col={0} />
      </div>
    )
  }

  const renderTaggedSubtitle = (t) => {
    let primaryComp = t.split('<b>')
    let textComponents = []
    for(let i in primaryComp) {
      if(i === 0) {
        textComponents.push({ text: primaryComp[i], bold: false })
        continue
      }
      let secondaryComp = primaryComp[i].split('</b>')
      if(secondaryComp.length > 1) {
        textComponents.push({ text: secondaryComp[0], bold: true })
        textComponents.push({ text: secondaryComp[1], bold: false })
      } else {
        textComponents.push({ text: secondaryComp[0], bold: false })
      }
    }
    return (
      <span>
        { textComponents.map((c, ci) => {
          return (
            <span key={`comp_${ci}`} style={{ color: c.bold ? '#9F9F9F' : '#333'}}>{ c.text }</span>
          )
        })}
      </span>
    )
  }
  
  return (
    <div>
      <div className="sec">
        <div className="container">
          <h1 className="hero__h small-margin">{ renderTaggedSubtitle(t('home.subtitle')) }</h1>
          { config.home_perex ? (
            <div className="home-perex_wrapper">
              <div className="home-perex">{ t('home.perex') }</div>
            </div>
          ) : null }
          <Grid container direction="row" justify="space-evenly" alignItems="center" className="single-doc-home-grid-container">
            <Grid item sm={4} xs={12}>
              <div className="row row--hero mt-medium">
                { loading ? renderSkeleton() : renderCategory() }
                {/* { renderSkeletonCategories() } */}
              </div>
            </Grid>
            <Grid item sm={3}>
              <div className="doc-cont-wrap">
                <div className="d-cont__title-wrap"><img src="/images/info-1.svg" alt="info-icon" className="info-icon" />
                  <p className="d-hero__title--little">{ t('document.help') }</p>
                </div>
                <div className="d-cont__text-wrap">
                  <p className="d-hero__title--little">{ t('document.step1_title') }</p>
                  <p className="d-hero__p--sidebar">{ t('document.step1_description') }</p>
                </div>
                <div className={`d-cont__text-wrap${!config.has_advocates ? '--last' : ''}`}>
                  <p className="d-hero__title--little">{ t('document.step2_title') }</p>
                  <p className="d-hero__p--sidebar">{ t('document.step2_description') }</p>
                </div>
              </div>
            </Grid>
          </Grid>          

          { !!config.partners && config.partners.length > 0 ? (
            <PartnersFooter />
          ) : null }

        </div>
      </div>
    </div>
  )
}

export default SingleDocHome