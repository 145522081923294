import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import RenderInBrowser from 'react-render-in-browser'
import { GlobalProvider } from './context/global'
import { HelpProvider } from './context/help'

const Wrapper = () => {
  return (
    <div>
      <RenderInBrowser ie only>
        <div className="unsupported-wrapper">
        <h4>Your current browser cannot display our content.</h4>
        <h5>Please make sure you are using a recent version of Google Chrome, Firefox or Microsoft Edge</h5>
        </div>
      </RenderInBrowser>
      <RenderInBrowser except ie>
        <GlobalProvider>
          <HelpProvider>
            <App />
          </HelpProvider>
        </GlobalProvider>
      </RenderInBrowser>
    </div>
  )
}

ReactDOM.render(<Wrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
