import moment from "moment";
import t, { saveLocale } from "./strings";

import cookie from "react-cookies";
import config from "../config.json";
import ReactGA from "react-ga4";

const isCookieAccepted = cookie.load('lawstudio_cookie_consent')
if(isCookieAccepted && isCookieAccepted.tracking && config.ga_key) {
  ReactGA.initialize(config.ga_key)
}

const LOCALE_C_KEY = "lawstudio__selected_locale";

const sortedArrayFromObject = (object, sortKey, asc) => {
  let array = [];
  for (let key in object) {
    let item = object[key];
    // zaur
    // item.id = key
    array.push(item);
  }
  array.sort((a, b) => {
    if (a[sortKey] > b[sortKey]) return asc ? 1 : -1;
    if (a[sortKey] < b[sortKey]) return asc ? -1 : 1;
    return 0;
  });
  return array;
};

const printDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

const idFromName = (name) => {
  return name.replace(/\s/g, "");
};

const currentLanguage = () => {
  return languageFromLocale(currentLocale()).code
}

const currentLocale = () => {
  let locale = cookie.load(LOCALE_C_KEY)
  if(!locale) {
    locale = config.default_locale
  }
  return locale
}

const languageFromLocale = (locale) => {
  switch (locale) {
    case "fr_FR":
      return { long: "Français", code: "fr" };
    case "fr_CM":
      return { long: "Français", code: "fr" };
    case "en_UK":
      return { long: "English", code: "en" };
    case "en_CM":
      return { long: "English", code: "en" };
    case "en_GH":
      return { long: "English", code: "en" };
    case "pl_PL":
      return { long: "Polski", code: "pl" };
    case "fr_BE":
      return { long: "Français", code: "fr" };
    case "nl_BE":
      return { long: "Nederlands", code: "nl" };
    default:
      return { long: "", code: "" };
  }
};

const userDataFields = [
  {
    label: "First name",
    type: "string",
    handle: "name_first",
  },
  {
    label: "Last name",
    type: "string",
    handle: "name_last",
  },
  {
    label: "Street name",
    type: "string",
    handle: "address_street",
  },
  {
    label: "Street number",
    type: "string",
    handle: "address_number",
  },
  {
    label: "City",
    type: "string",
    handle: "address_city",
  },
  {
    label: "Zip code",
    type: "string",
    handle: "address_zip",
  },
  {
    label: "VAT number",
    type: "string",
    handle: "vat",
  },
  {
    label: "Email",
    type: "string",
    handle: "email",
  },
  {
    label: "Phone number",
    type: "string",
    handle: "phone",
  }, {
    label: 'Company name',
    type: 'string',
    handle: 'company_name'
  }, {
    label: 'Company Type',
    type: 'string',
    handle: 'company_type'
  }, {
    label: 'Company website',
    type: 'string',
    handle: 'company_website'
  }
];

const companyTypeOptions = {
  be: [
    'personal',
    'srl',
    'sprl',
    'sa',
    'sc',
    'asbl',
    'snc',
    'scs',
    'sca',
    'ei'
  ],
  fr: [
    'sarl',
    'sas',
    'sa',
    'sc',
    'snc',
    'sca',
    'scs',
    'eirl',
    'es'
  ]
}

const companyTypesBeci = [
  'srl',
  'sc',
  'sa',
  'snc',
  'scomm',
  'se',
  'sce',
  'asbl',
  'aisbl',
  'self_employed',
  'other',
]

const validatePhoneNumber = number => {
  const re = /^[+]\d{11,}$/
  return re.test(number)
}

const areSectionConditionsMet = (section, values) => {
  if((!section.conditions || section.conditions?.length === 0) && !section.condition) {
    return true
  } else if(section.conditions?.length > 0) {
    for(let i in section.conditions) {
      let cond = section.conditions[i]
      if(!isConditionMet(cond, values)) {
        return false
      }
    }
    return true
  } else {
    return isConditionMet(section.condition, values)
  }
}

const isConditionMet = (cond, values) => {
  let targetValue = cond.value
  let sourceValue = values[cond.variable]
  if(typeof targetValue === 'number') {
    sourceValue = parseFloat(sourceValue)
  } 
  if(cond.relation === 'EQ') {
    return sourceValue === targetValue
  } else if(cond.relation === 'NE') {
    return sourceValue !== targetValue
  } else if(cond.relation === 'GT') {
    return sourceValue > targetValue
  } else if(cond.relation === 'LT') {
    return sourceValue < targetValue
  }
}

const dateValueFormat = 'DD/MM/YYYY'

const isOverflown = (el) => {
  if(el) {
    const { scrollHeight, clientHeight, scrollWidth, clientWidth } = el
    return scrollHeight > clientHeight || scrollWidth > clientWidth
  }else {
    return false
  }
}

const isBeci = () => {
  return config.partner === 'tKQgHPEaRHz1bMAlCBzk'
}

const isSimplymoveV2= () => {
  return config.partner === 'raGLxpXV3k1Nnx6l1hWu'
}

const isSimplyMove = () => {
  return config.partner === 'VNIQuk0to4ahhQyzgagz' || config.partner === 'raGLxpXV3k1Nnx6l1hWu'
}

const CONTACT_ID_VARIABLES = [
  'do_co_seller_customers_id',
  'do_co_customers_id',
  'co_customers_id'
]

const validateURL = (url) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(url);
}

// Get file data
const getFileData = (file, fType = '') => new Promise((resolve, reject) => {
  if (file) {
    let reader = new FileReader()

    reader.onload = async (e) => {
      let components = file.name.split('.')
      const format = components[components.length - 1]
      components.splice(components.length - 1, 1)
      const name = components.join('.')
      const type = file.type || fType
      const data = { data: e.target.result, data_plain: e.target.result.split(',')[1], name: name, format: format, type: type }
      resolve(data)
    }
    reader.onerror = (err) => {
      console.log('reader on error', err)
      reject(err)
    }
    reader.readAsDataURL(file)
  }
})

// Convert base64 to blob
const base64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

// Blob to file
const blobToFile = (blob, name) => {
  blob.lastModifiedDate = new Date();
  blob.name = name;
  return blob;
}

// Sort array of objects
const sortArrayOfObjects = (arr, value, order) => {
  const splitted = value.split('.');
  return arr.sort((a, b) => {
    let valA = splitted.length === 1 ? a[splitted[0]] : a[splitted[0]][splitted[1]];
    let valB = splitted.length === 1 ? b[splitted[0]] : b[splitted[0]][splitted[1]];

    if(typeof valA === 'string' && typeof valB === 'string') {
      valA = valA.toLowerCase()
      valB = valB.toLowerCase()
    }
    if(valA < valB) return -1 * (order === 'asc' ? -1 : 1)
    else if(valA > valB) return 1 * (order === 'asc' ? -1 : 1)
    else return 0
  });
}

export {
  sortedArrayFromObject,
  printDate,
  idFromName,
  t,
  languageFromLocale,
  saveLocale,
  LOCALE_C_KEY,
  currentLanguage,
  currentLocale,
  ReactGA,
  userDataFields,
  companyTypeOptions,
  validatePhoneNumber,
  areSectionConditionsMet,
  dateValueFormat,
  isOverflown,
  CONTACT_ID_VARIABLES,
  isBeci,
  isSimplyMove,
  validateURL,
  getFileData,
  base64toBlob,
  blobToFile,
  sortArrayOfObjects,
  companyTypesBeci,
  isSimplymoveV2,
};
