export const welcome_email_template = (title, message1, message2, message3, messageFooter, link, btnText) => {
  return `
  <html style="margin:0;padding:0;">
    <head>
      <style>
        p {
          margin: 0;
        }
        a {
          text-decoration: none;
        }
        .main-text {
          font-size: 17px;
          line-height: 22px;
          color: #000;
          margin-bottom: 22px;
          text-align: center;
        }
        .main-text.main-text--last {
          margin-bottom: 40px;
        }
        .title a {
          color: #2801D8;
        }
        .footer-link a {
          color: #2801D8;
          text-decoration: none;
        }
      </style>
    </head>
    <body style="margin:0;padding:0;">
      <table style="width:100%;height:100%;padding:70px 0;background-color:#F8F9FA;">
        <tbody>
          <tr>
            <td style="text-align:center;">
              <table style="max-width:560px;width:100%;margin:0 auto;">
                <tbody>
                  <tr>
                    <td>
                      <table style="background-color:#fff;max-width: 100%;margin:0 auto;padding:50px 35px 21px;border-radius: 8px;font-family: 'Helvetica', sans-serif !important;">
                        <tbody>
                          <tr>
                            <td>
                              <table style="width:100%;margin-bottom:44px;">
                                <tbody>
                                  <tr>
                                    <td style="text-align:center;">
                                      <img src="https://beci-lawstudio.web.app/images/beci_email_logo.png" alt="icon" style="width:100px;vertical-align:middle;" />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style="width:100%">
                                <tbody>
                                  <tr>
                                    <td style="text-align:center;">
                                      <p class="title" style="font-size:24px;line-height:31px;color:#2801D8;font-weight:bold;margin-bottom:36px;">${title}</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p class="main-text">${message1}</p> 
                                      <p class="main-text">${message2}</p>
                                      <p class="main-text main-text--last">${message3}</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="text-align:center;">
                                      <table style="width:100%">
                                        <tbody>
                                          <tr>
                                            <td style="text-align:center;">
                                              <span style="text-align:center;background-color:#2801D8;border-radius:4px;display:inline-block;">
                                                <a href="${link}" style="background:#2801D8;"><span style="display:block;background-color:#2801D8;padding:0 5px;font-size:17px;line-height:20px;font-weight:bold;background-color:#2801D8;color:#ffffff;border-radius:4px;text-decoration:none;border-top:12px solid #2801D8;border-bottom:12px solid #2801D8;border-right:26px solid #2801D8;border-left:26px solid #2801D8;">Retour sur la plateforme</span></a>
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style="width:100%;margin-top:45px;">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div style="background:#E0E0E0;width:100%;margin-bottom:33px;height:1px;font-size:1px;"><span style="opacity:0;">a</span></div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="text-align:center;">
                                      <p class="footer-link" style="font-size:14px;line-height:17px;color:#9f9f9f;">${messageFooter} <a href="mailto:info@beci.be">info@beci.be</a></p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </body>
  </html>
  `
}