import firebase from './firebase'
import * as functions from '../services/functions'
import { currentLanguage } from '../utils'
import config from '../config.json'

const auth = firebase.auth()
auth.languageCode = 'fr'

const createUserStateChangedListener = (listener) => {
  return auth.onAuthStateChanged(listener)
}

const isUserSignedIn = () => {
  return !!auth.currentUser
}

const signInWithEmailAndPassword = async (email, password) => {
  try {
    let result = await auth.signInWithEmailAndPassword(email, password)
    return result
  } catch (err) {
    return { error: err }
  }
}

const createUserWithEmailAndPassword = async (email, password, code = '') => {
  try {
    let result = await auth.createUserWithEmailAndPassword(email, password)
    if(config.multiple_user_types) {
      let codeResult = await functions.assignUserTypeWithCode(code)
      if(codeResult.error) {
        return codeResult
      }
    }
    return result
  } catch (err) {
    return { error: err }
  }
}

const updateUserPassword = async (password) => {
  try {
    await auth.currentUser.updatePassword(password)
    return {}
  } catch (err) {
    return { error: err }
  }
}

const sendPasswordResetEmail = async (email) => {
  let result = await functions.sendPasswordResetEmail(email, currentLanguage())
  return result
}

const signOut = async () => {
  try {
    await auth.signOut()
    return {}
  } catch (err) {
    return { error: err }
  }
}

const userId = () => {
  if (!!auth.currentUser) {
    return auth.currentUser.uid
  } else {
    return null
  }
}

const userIdToken = async () => {
  if (!!auth.currentUser) {
    return await auth.currentUser.getIdToken()
  } else {
    return null
  }
}

const checkVerificationCode = async (code, partner) => {
  try {
    return await functions.checkVerificationCode({ code, partner })
  }
  catch (error) {
    return { error }
  }
}

const isAlreadyRegistered = async (email) => {
  try {
    const res = await auth.fetchSignInMethodsForEmail(email)
    return res.length 
  } catch (err) {
    console.log(err)
  }
}

export {
  isUserSignedIn,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserStateChangedListener,
  signOut,
  updateUserPassword,
  userId,
  userIdToken,
  checkVerificationCode,
  isAlreadyRegistered,
}