import React, { useState, useEffect, useRef } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";

import { QuestionV2, Auth, PreviewSkeleton, DocumentDetailVariables, SidePanel, SPVariables } from "../components";

import { createDocument, createPreview } from "../services/functions";

import { listenForUserDocument, updateUserDraft } from "../services/database";

import { isUserSignedIn } from "../services/auth";

import { Dialog, DialogContent } from "@material-ui/core";

import { t, ReactGA, userDataFields, areSectionConditionsMet } from "../utils";

import config from "../config.json";

import { v4 as uuidv4 } from 'uuid';

// import { pdfjs, Document, Page } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import Pdf from "@mikecousins/react-pdf";
import moment from "moment";
import { Save, RemoveRedEye, GetApp } from '@material-ui/icons'
import Button from '../components/UI/Button'
import Input from '../components/UI/Input'
import CustomTooltip from '../components/UI/CustomTooltip'

let scrollToItemTimeout = null

const DocumentForm = (props) => {
  const { startLoading, stopLoading, showSnackbar, partnerData, user, history } = props;
  const [doc, setDoc] = useState(null);
  const [draftId, setDraftId] = useState("");
  const [answers, setAnswers] = useState({});
  const [preview, setPreview] = useState({
    data: null,
    numberOfPages: 0,
  });
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [auth, setAuth] = useState({
    isOpen: false,
    title: "",
    onRegister: () => { },
    onLogin: () => { },
  });
  const [templateObjectWithUniqueVarIndexes, setTemplateObjectWithUniqueVarIndexes] = useState(null);
  // const [scrollingToItem, setScrollingToItem] = useState(false)
  const [templateObject, setTemplateObject] = useState({})
  const [totalProgress, setTotalProgress] = useState(0)
  const [sidePanelOpened, setSidePanelOpened] = useState(true)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const bodyWrapEl = useRef()

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight - 100,
      left: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    const { documents, location } = props;
    let id = props.match.params.id;
    let d;
    if (!id) {
      id = "";
      d = null;
      setDoc(null);
      setAnswers({});
      return;
    } else {
      d = documents[id];
    }
    if(!d) {
      return
    }
    let ans = {};
    let drftId = undefined;
    if (!!location.state && !!location.state.draftId) {
      ans = location.state.answers;
      drftId = location.state.draftId;
    }
    setupDocument(id, d, ans, drftId);
    setTemplateObjectWithUniqueVarIndexes(convertToTemplateObjWithUniqueVarIndexes({...d}))
    setTemplateObject({...d})
  }, [props.documents]);

  useEffect(() => {
    refreshAnswers();
  }, [props.user]);

  useEffect(() => {
    props.refreshInteractions();
  });

  useEffect(() => {
    const { history, documents } = props
    const id = props.match.params.id
    const document = documents[id]
    if (document && props.shouldPushToSubscription(document.category)) {
      const link = { pathname: '/subscription', state: { documentId: document.id } }
      history.push(link)
    }
  }, [])

  // Update progress
  useEffect(() => {
    if(templateObject && Object.keys(answers).length > 0) {
      setTotalProgress(progress())
    }
  }, [answers, templateObject]);

  const setupDocument = (id, d, answrs, drftId) => {
    if (!d || !!doc) {
      return;
    }

    setDoc(d);
    setAnswers({ ...answrs });
    setDraftId(drftId);
  };

  const showPreview = async () => {
    // startLoading('preview', t('form.preview_generation'))
    setPreview({
      data: null,
      numberOfPages: 0,
    });
    setLoadingPreview(true);
    setShowPreviewModal(true)

    // scrollToBottom();

    let result = await createPreview(doc, answers /*processedAnswers()*/);
    if (result.error) {
      showError(
        !!result.error.message
          ? result.error.message
          : "An error occured when generating document preview. Please try again later"
      );
      setLoadingPreview(false);
      return;
    }

    setPreview({
      data: result.data,
      numberOfPages: 0,
    });

    ReactGA.event({
      category: "document",
      action: "Preview created",
    });

    setLoadingPreview(false);
  };

  const showError = (message) => {
    showSnackbar({ text: message, color: "error" });
  };

  const trackOnLogin = () => {
    ReactGA.event({
      category: "auth",
      action: "User signed in",
      label: "Document creation",
    });
  };

  const trackOnRegister = () => {
    ReactGA.event({
      category: "auth",
      action: "User registered",
      label: "Document creation",
    });
  };

  const trackOnCreateDocument = () => {
    ReactGA.event({
      category: "document",
      action: "Document generated",
    });
  }

  const saveDocument = async () => {
    if (!isUserSignedIn()) {
      openAuth(
        t("form.auth_document"),
        () => {
          closeAuth();
          saveDocument();
          trackOnRegister();
        },
        () => {
          closeAuth();
          saveDocument();
          trackOnLogin();
        }
      );
      return;
    }

    const {
      setGeneratingDocument,
      setGeneratingDocumentCancelled,
      getUserDocuments,
    } = props;

    startLoading("creatingDocument", t("form.loading_v2"));

    trackOnCreateDocument()
    let result = await createDocument(doc, answers /*processedAnswers()*/);
    if (!!draftId) {
      await updateUserDraft(null, draftId);
    }

    stopLoading("creatingDocument");
    getUserDocuments();

    setGeneratingDocumentCancelled(false);

    let listener = listenForUserDocument(result.documentId, (data) => {
      setGeneratingDocument({ ...data, listener: listener });      
      if (!!data.files && !!data.files) {
        let complete = true;
        for (let key in data.files) {
          if (data.files[key].generation_progress < 1) {
            complete = false;
          }
        }
        if (complete) {
          getUserDocuments();
          clearInterval(listener);
        }
      }
    });
  };

  // const processedAnswers = () => {
  //   let a = {}
  //   for(let key in answers) {
  //     let question = questionForVariableKey(key)
  //     if(!question) {
  //       continue
  //     }
  //     if(question.type === 'date') {
  //       if(answers[key]) {
  //         a[key] = moment(answers[key]).format('DD/MM/YYYY')
  //       } else {
  //         a[key] = ''
  //       }
  //     } else {
  //       a[key] = answers[key]
  //     }
  //   }
  //   return a
  // }

  const saveDraft = async () => {
    const { getUserDrafts } = props;
    if (!isUserSignedIn()) {
      openAuth(
        t("form.auth_draft"),
        () => {
          closeAuth();
          saveDraft();
          trackOnRegister();
        },
        () => {
          closeAuth();
          saveDraft();
          trackOnLogin();
        }
      );
      return;
    }

    startLoading("savingDraft");
    let draft = {
      name: doc.name,
      answers: answers,
      document: doc.id,
    };

    let result = await updateUserDraft(draft, draftId);
    if (result.id) {
      setDraftId(result.id);
    }
    stopLoading("savingDraft");
    getUserDrafts();
    if (!result.error) {
      showSnackbar({ text: t("form.draft_success"), color: "success" });
    } else {
      showSnackbar({ text: result.error.message, color: "error" });
    }
  };

  const refreshAnswers = () => {
    if (!doc) {
      return;
    }
    let blcks = doc.form;
    let answrs = answers;
    for (let i in blcks) {
      for (let j in blcks[i].questions) {
        if (!answrs[blcks[i].questions[j].key]) {
          answrs[blcks[i].questions[j].key] = defaultAnswerForQuestion(
            blcks[i].questions[j]
          );
        }
      }
    }
    setAnswers({ ...answrs });
  };

  const questionForVariableKey = (key) => {
    if (!doc) {
      return null
    }
    let blcks = doc.form;
    for(let i in blcks) {
      for(let j in blcks[i].questions) {
        if(blcks[i].questions[j].key === key) {
          return blcks[i].questions[j]
        }
      }
    }
    return null
  }

  const defaultAnswerForQuestion = (question) => {
    switch (question.type) {
      case "string": {
        if(partnerData && partnerData.user_data_prefill && partnerData.user_data_prefill[question.key]) {
          return defaultAnswerWithUserData(partnerData.user_data_prefill[question.key]);
        }
        return "";
      }
      case "number":
        if(partnerData && partnerData.user_data_prefill && partnerData.user_data_prefill[question.key]) {
          return defaultAnswerWithUserData(partnerData.user_data_prefill[question.key]);
        }
        return "";
      case "date":
        return null;
      case "multiselect":
        return [];
      case "select":
        return undefined;
      default:
        return ''
    }
  };

  const companyTypeCountry = () => {
    return config.company_type_country ? config.company_type_country : 'be'
  }

  const defaultAnswerWithUserData = (field) => {
    if (!user || Object.keys(user).length === 0) {
      return "";
    }
    if(user[field]) {
      return user[field]
    }
    return ''
  };

  const documentValuesChangeHandler = (variable, value) => {
    if(typeof variable === 'object' && Array.isArray(variable)) {
      let dv = {...answers}
      for(let i in variable) {
        dv[variable[i]] = value[i]
      }
      setAnswers({...dv})
    } else {
      setAnswers({...answers, [variable]: value })      
    }
  }

  const openAuth = (message, onRegister, onLogin) => {
    setAuth({
      isOpen: true,
      title: message,
      onRegister,
      onLogin,
    });
  };

  const closeAuth = () => {
    setAuth({
      isOpen: false,
      title: "",
    });
  };

  const convertToTemplateObjWithUniqueVarIndexes = (obj) => {
    const copyOfTemplate = {...obj};
    const tmplSections = [...copyOfTemplate.content];
    const updatedSections = tmplSections.map((s, i) => {
      const section = {...s};
      if(section.variable) {
        section.idx = `${section.variable}-${uuidv4()}`;
        return section;
      }else if(section.variables) {
        section.variables = [...section.variables].map((v => ({...v, idx: `${v.variable}-${uuidv4()}`})));
        return section;
      }else {
        return section;
      }
    });
    copyOfTemplate.sections = updatedSections;
    
    if(copyOfTemplate.footer) {
      const updatedFooter = {...copyOfTemplate.footer}
      if(updatedFooter.variables) {
        updatedFooter.variables = [...updatedFooter.variables].map((v => ({...v, idx: `${v.variable}-${uuidv4()}`})));
      }
      copyOfTemplate.footer = updatedFooter
    }
    return copyOfTemplate;
  }

  // On variable click - side panel
  const variableItemClickHandler = async (id) => {
    const element = bodyWrapEl.current.querySelector(`[data-id="${id}"]`)
    if(element) {
      const input = element.querySelector('input') || element.querySelector('textarea')
      if(input) {
        input.focus()
      }
      // setScrollingToItem(true)
      window.scrollTo({top: element.getBoundingClientRect().top + window.pageYOffset - 100, behavior: "smooth"})
      // scrollToItemTimeout = setTimeout(() => setScrollingToItem(false), 1000)
    }
  }

  // On section click - side panel
  const sectionClickHandler = (id) => {
    const element = bodyWrapEl.current.querySelector(`[data-index="${id}"]`)
    if(element) {
      // setScrollingToItem(true)
      window.scrollTo({top: element.getBoundingClientRect().top + window.pageYOffset - 100, behavior: "smooth"})
      // scrollToItemTimeout = setTimeout(() => setScrollingToItem(false), 1000)
    }
  }

  // Extract variable types from sections
  const extractVariableTypesFromSections = (sections) => {
    let variablesWithTypes = extractVariablesWithTypesFromSections(sections)
    let types = {}
    for(let i in variablesWithTypes) {
      types[variablesWithTypes[i].variable] = variablesWithTypes[i].type
    }
    return types
  }

  const extractVariablesWithTypesFromSections = (sections) => {
    let variablesWithTypes = []
    for(let i in sections) {
      let section = sections[i]
      if(!areSectionConditionsMet(section, answers)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesWithTypesFromSections(section.sections)
        for(let i in vars) {
          variablesWithTypes.push(vars[i])
        }
      }
      if(section.type === 'question') {
        // if(isCoverPageVariable(section.variable)) {
        //   continue
        // }
        variablesWithTypes.push({variable: section.variable, type: section.data_type})
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {
          // if(isCoverPageVariable(section.variables[vi].variable)) {
          //   continue
          // }
          variablesWithTypes.push({variable: section.variables[vi].variable, type: section.variables[vi].type})
        }
      }
    }
    let uniqueVariables = [...new Set(variablesWithTypes)];
    return uniqueVariables
  }

  // Calculate progress
  const progress = () => {
    let p = 0;
    let variableKeys = activeVariableKeys()
    if(variableKeys) {
      let totalVariables = variableKeys.length

      for(let i in variableKeys) {
        if(typeof variableKeys[i] === 'string') {
          if(Array.isArray(answers[variableKeys[i]])) {
            if(answers[variableKeys[i]].length !== 0) {
              p += 1 / variableKeys.length
            }
          }else if(!!answers[variableKeys[i]]) {
            p += 1 / variableKeys.length
          }
        }else {
          if(answers[variableKeys[i].belongs_to] && !!answers[variableKeys[i].belongs_to][variableKeys[i].index][variableKeys[i].variable]) {
            p += 1 / variableKeys.length
          }
        }
      }
    }
    return p
  }

  // Active variable keys
  const activeVariableKeys = () => {
    let templateCopy = {...templateObject}
    if(Object.keys(templateCopy).length > 0 && templateCopy.content) {
      let sections = [...templateCopy.content]
      // console.log(sections)
      if(templateCopy.footer) {
        sections.push({...templateCopy.footer, type: templateCopy.footer.type || 'text'})
      }
      return extractVariablesFromSections(sections, true)
    }
  }

  const extractVariablesFromSections = (sections, active = true, full = false) => {
    let variables = []
    for(let i in sections) {
      let section = sections[i]
      if(active && (section.condition || section.conditions) && !areSectionConditionsMet(section, answers)) {
        continue
      }
      if(section.sections) {
        let vars = extractVariablesFromSections(section.sections)
        for(let i in vars) {
          variables.push(vars[i])
        }
      }
      if(section.type === 'question') {
        // if(isCoverPageVariable(section.variable)) {
        //   continue
        // }
        if(full) {
          variables.push({ variable: section.variable, type: section.data_type, options: section.options })
        } else {
          variables.push(section.variable)
        }
      } if(section.type === 'text' || section.type === 'heading1' || section.type === 'heading2' || section.type === 'heading3') { // todo make more general
        for(let vi in section.variables) {  
          // if(isCoverPageVariable(section.variables[vi].variable)) {
          //   continue
          // }
          if(full) {
            variables.push(section.variables[vi])
          } else {
            if(section.repeatable_section_id && answers[section.repeatable_section_id]) {
              answers[section.repeatable_section_id].forEach((item, index) => {
                let splittedVar = section.variables[vi].variable.split('_')
                let lastEl = splittedVar[splittedVar.length - 1] 
                if(lastEl !== 'id') {
                  variables.push({variable: section.variables[vi].variable, index, belongs_to: section.repeatable_section_id})
                }
              })
            }else {
              variables.push(section.variables[vi].variable)
            }
          }
        }
      } else if(section.type === 'bullet_list') {
        for(let bi in section.items) {
          for(let vi in section.items[bi].variables) {
            // if(isCoverPageVariable(section.items[bi].variables[vi].variable)) {
            //   continue
            // }
            if(full) {
              variables.push(section.items[bi].variables[vi])
            } else {
              variables.push(section.items[bi].variables[vi].variable)
            }
          }
        }
      }
    }
    let uniqueVariables = [...new Set(variables)];
    return uniqueVariables
  }

  return (
    <div className={`sec sec-document-form document-detail ${!sidePanelOpened ? 'sp-closed' : ''}`}>
      {!!doc ? (
        <>
          <div className={sidePanelOpened ? "document-detail__body opened" : "document-detail__body"}>
            <div className="document-detail__body_inner">
              <div className="form-content-actions">
                <div className="form-content-actions__inner">
                  <button onClick={saveDraft}><Save /> {t("general.save")}</button>
                  <button onClick={showPreview}><RemoveRedEye /> {t("form.preview")}</button>
                  <button onClick={saveDocument} className="download-btn"><GetApp /> {t("form.get_document")}</button>
                </div>
              </div>
              <div className="form-content container wrap" ref={bodyWrapEl}>
                <h1>{doc.name}</h1>
                <DocumentDetailVariables
                  templateData={templateObjectWithUniqueVarIndexes}
                  documentValues={answers}
                  onValuesChange={documentValuesChangeHandler}
                />
                {/* <div className="document-form-footer-container">
                  <div className="document-form-footer">
                    <div
                      onClick={saveDraft}
                      className="icon-text-wrap w-inline-block close-icn">
                      <img
                        src="/images/save.svg"
                        alt="download-icon"
                        className="download-icon"/>
                      <h4 className="download-title">
                        {t("form.save_draft")}
                      </h4>
                    </div>
                    <div
                      onClick={showPreview}
                      className="icon-text-wrap w-inline-block">
                      <img
                        src="/images/show.svg"
                        alt="download-icon"
                        className="preview-icon"/>
                      <h4 className="download-title">{t("form.preview")}</h4>
                    </div>
                    <div
                      onClick={saveDocument}
                      className="ml-5 q-btn btn--red w-inline-block">
                      <h4 className="nav__h-2 nav__h--white">
                        {t("form.get_document")}
                      </h4>
                      <div className="growing-div-2"></div>
                    </div>
                  </div>
                </div> */}
                <Dialog
                  open={showPreviewModal}
                  onClose={() => setShowPreviewModal(false)}
                  scroll="body"
                  className={`doc-preview-dialog doc-preview-dialog--${config.class_handle}`}
                >
                  <DialogContent>
                    {!!preview.data ? (
                      <div className="pdf-container">
                        <Pdf
                          className="hidden-pdf"
                          file={JSON.parse(
                            JSON.stringify(
                              `data:application/pdf;base64,${preview.data}`
                            )
                          )}
                          onDocumentLoadSuccess={(doc) => {
                            setPreview({
                              ...preview,
                              numberOfPages: doc._pdfInfo.numPages,
                            });
                          }}
                          page={-1}
                        />
                        {preview.numberOfPages > 0
                          ? [...Array(preview.numberOfPages)].map((page, pi) => {
                            return (
                              <div
                                key={`preview_page_${pi}`}
                                className="preview-container"
                              >
                                <Pdf
                                  file={`data:application/pdf;base64,${preview.data}`}
                                  className="pdf-page"
                                  page={pi + 1}
                                />
                                {config.env !== "development" &&
                                  config.use_watermark ? (
                                    <div className="watermark-container">
                                      <div className="watermark" />
                                    </div>
                                  ) : null}
                              </div>
                            );
                          })
                          : null}
                      </div>
                    ) : loadingPreview ? (
                      <PreviewSkeleton />
                    ) : null}
                  </DialogContent>
                </Dialog>
                {/* {!!preview.data ? (
                  <div className="pdf-container">
                    <Pdf
                      className="hidden-pdf"
                      file={JSON.parse(
                        JSON.stringify(
                          `data:application/pdf;base64,${preview.data}`
                        )
                      )}
                      onDocumentLoadSuccess={(doc) => {
                        setPreview({
                          ...preview,
                          numberOfPages: doc._pdfInfo.numPages,
                        });
                      }}
                      page={-1}
                    />
                    {preview.numberOfPages > 0
                      ? [...Array(preview.numberOfPages)].map((page, pi) => {
                        return (
                          <div
                            key={`preview_page_${pi}`}
                            className="preview-container"
                          >
                            <Pdf
                              file={`data:application/pdf;base64,${preview.data}`}
                              className="pdf-page"
                              page={pi + 1}
                            />
                            {config.env !== "development" &&
                              config.use_watermark ? (
                                <div className="watermark-container">
                                  <div className="watermark" />
                                </div>
                              ) : null}
                          </div>
                        );
                      })
                      : null}
                  </div>
                ) : loadingPreview ? (
                  <PreviewSkeleton />
                ) : null} */}
              </div>
            </div>

            <SidePanel onTriggerClick={() => setSidePanelOpened(!sidePanelOpened)} isOpened={sidePanelOpened}>
              <SPVariables 
                templateData={templateObjectWithUniqueVarIndexes} 
                documentValues={answers}
                onSectionClick={sectionClickHandler}
                onItemClick={variableItemClickHandler}
                variableTypes={extractVariableTypesFromSections(templateObject.sections)}
                totalProgress={totalProgress}
              />
            </SidePanel>
          </div>
        </>
      ) : null}
      
      <Dialog
        scroll="body"
        maxWidth="lg"
        open={auth.isOpen}
        onEscapeKeyDown={closeAuth}
      >
        {/* <div className="pop-up"> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Auth
            title={auth.title}
            startLoading={startLoading}
            stopLoading={stopLoading}
            showSnackbar={showSnackbar}
            onLogin={auth.onLogin}
            onPasswordReset={() => { }}
            onRegister={auth.onRegister}
            getUser={props.getUser}
            onClose={closeAuth}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default DocumentForm;
