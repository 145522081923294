import React from 'react' 
import { Link } from 'react-router-dom'
import { ArrowForward } from '@material-ui/icons'

import { t } from '../utils'
import config from '../config.json'
import lawstudioIcon from '../assets/images/lawstud-io-icon.svg'

const FooterBeci = () => {
  return (
    <footer className={`footer-2 footer-2--${config.class_handle}`}>
      <div className="footer-2__top">
        <div className="container">
          <div className="content">
            <div className="logo">
              <img src="/images/footer_logo.png" alt="Beci" />
            </div>
            <ul>
              <li><Link to="/">{t('nav.home')}</Link></li>
              <li><Link to="/documents">{t('nav.documents')}</Link></li>
              <li><Link to="/contact">{t('nav.contact')}</Link></li>
              {/* <li><Link to="/our-philosophy">{t('our_philosophy.title')}</Link></li> */}
            </ul>
          </div>
          <div className="content content--2">
            <div className="box">
              <p className="box__text"><span>Legal.beci.be</span> {t('banner.powered_by')} <strong>Carbon</strong>.</p>
              <div className="manage">
                <p>{t('banner.manage')}</p>
                <a href="https://getcarbon.ai/" target="_blank" rel="noreferrer">{t('banner.discover_carbon')} <ArrowForward /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2__bottom">
        <div className="container">
          <div className="content">
            <div className="copyright">
              <p>
                &copy;{new Date().getFullYear()} {t('footer.copyright')} <span className="divider">-</span> <Link to="/privacy-policy" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}>{t('footer.privacy_policy')}</Link> <span className="divider">-</span> <Link to="/terms" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}>{t('footer.terms_of_use')}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterBeci