import React, { useState, useEffect } from "react";
import {
  sortedArrayFromObject,
  idFromName,
  t,
  currentLanguage,
  currentLocale,
  isBeci
} from "../utils";

import { CategoryHome, CategorySkeleton, PartnersFooter } from "../components";

import config from "../config.json";
import beciStep1Icon from '../assets/images/step_1.png'
import beciStep2Icon from '../assets/images/step_2.png'
import beciStep3Icon from '../assets/images/step_3.png'
import beciStep4Icon from '../assets/images/step_4.png'

const Home = (props) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    props.refreshInteractions();
  });

  useEffect(() => {
    setLoading(true);
    let categoriesArray = sortedArrayFromObject(props.categories, "name", true);
    let filteredCategories = categoriesArray.filter(a => a.meta.locale === currentLocale())
    setCategories(filteredCategories);
    if (filteredCategories.length > 0) {
      setLoading(false);
    }
  }, [props.categories]);

  const openDocumentsForCategory = (category) => () => {
    const { history } = props;
    history.push({
      pathname: "/documents",
      params: { category: `#${idFromName(category.name)}` },
    });
  };

  const renderCategories = () => {
    let rows = [[]];
    let currentRow = 0;
    for (let i in categories) {
      if (rows[currentRow].length === 3) {
        rows.push([]);
        currentRow++;
      }
      rows[currentRow].push(categories[i]);
    }
    return rows.map((row, ri) => {
      return (
        <div key={`row_${ri}`} className="first-row">
          {row.map((category, ci) => {
            return (
              <CategoryHome
                key={`row_${ri}_category_${ci}`}
                category={category}
                onClick={openDocumentsForCategory(category)}
                {...props}
              />
            );
          })}
        </div>
      );
    });
  };

  const renderSkeletonCategories = () => {
    let rows;
    if (config.render_skeleton_categories) {
      let render = config.render_skeleton_categories;
      rows = [[]];
      for (let i = 0; i < render; i++) {
        rows[0].push("");
      }
    } else {
      rows = [["", "", ""]];
    }
    return rows.map((row, ri) => {
      return (
        <div key={`row_${ri}`} className="first-row">
          {row.map((category, ci) => {
            return (
              <CategorySkeleton
                col={ci + 1}
                key={`row_${ri}_category_skeleton_${ci}`}
              />
            );
          })}
        </div>
      );
    });
  };

  const renderTaggedSubtitle = (t) => {
    let primaryComp = t.split("<b>");
    let textComponents = [];
    for (let i in primaryComp) {
      if (i === 0) {
        textComponents.push({ text: primaryComp[i], bold: false });
        continue;
      }
      let secondaryComp = primaryComp[i].split("</b>");
      if (secondaryComp.length > 1) {
        textComponents.push({ text: secondaryComp[0], bold: true });
        textComponents.push({ text: secondaryComp[1], bold: false });
      } else {
        textComponents.push({ text: secondaryComp[0], bold: false });
      }
    }
    return (
      <span>
        {textComponents.map((c, ci) => {
          return (
            <span
              key={`comp_${ci}`}
              style={{ color: c.bold ? "#9F9F9F" : "#333" }}
            >
              {c.text}
            </span>
          );
        })}
      </span>
    );
  };

  const renderBeciSteps = () => {
    return (
      <div className="first-row3 beci-home-steps">
        <div className="col col--f">
          <div
            data-w-id="e6edfe9a-9b27-7149-031f-f3bdea14b9da"
            className="faciles-cont"
          >
            <div>
              <img
                src={beciStep1Icon}
                alt=""
                className={`facilies-icon facilies-icon--${config.class_handle}`}
              />
            </div>
            <p className="facilies-p">1. {t("home.step1")}</p>
          </div>
        </div>
        <div className="col col--f">
          <div
            data-w-id="e6edfe9a-9b27-7149-031f-f3bdea14b9da"
            className="faciles-cont"
          >
            <div>
              <img
                src={beciStep2Icon}
                alt=""
                className={`facilies-icon facilies-icon--${config.class_handle}`}
              />
            </div>
            <p className="facilies-p">{t("home.step2")}</p>
          </div>
        </div>
        <div className="col col--f">
          <div
            data-w-id="e6edfe9a-9b27-7149-031f-f3bdea14b9df"
            className="faciles-cont"
          >
            <div>
              <img
                src={beciStep3Icon}
                alt=""
                className={`facilies-icon facilies-icon--${config.class_handle}`}
              />
            </div>
            <p className="facilies-p">{t("home.step3")}</p>
          </div>
        </div>
        <div className="col col--f">
          <div
            data-w-id="e6edfe9a-9b27-7149-031f-f3bdea14b9e4"
            className="faciles-cont"
          >
            <div>
              <img
                src={beciStep4Icon}
                alt=""
                className={`facilies-icon facilies-icon--${config.class_handle}`}
              />
            </div>
            <p className="facilies-p">{t("home.step4")}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="sec">
        <div className="container">
          <h1 className={`hero__h h hero__h--${config.class_handle}`}>
            {renderTaggedSubtitle(t("home.subtitle"))}
          </h1>
          {isBeci() && <h2 className={`subtitle-2 subtitle-2--${config.class_handle}`}>{t('home.subtitle_2')}</h2>}
          <div className="row row--hero">
            {loading ? renderSkeletonCategories() : renderCategories()}
            {/* { renderSkeletonCategories() } */}
          </div>

          {!!config.partners && config.partners.length > 0 ? (
            <PartnersFooter />
          ) : null}

          <div className="title-wrap2">
            <div className="thin-line"></div>
            <h5 className="hero__h5">{t("home.steps_title")}</h5>
            <div className="thin-line"></div>
          </div>
          {isBeci() 
            ? renderBeciSteps()
            : (
              <div className="first-row3">
                <div className="col col--f">
                  <div
                    data-w-id="e6edfe9a-9b27-7149-031f-f3bdea14b9da"
                    className="faciles-cont"
                  >
                    <img
                      src="/images/choose.svg"
                      alt=""
                      className={`facilies-icon facilies-icon--${config.class_handle}`}
                    />
                    <p className="facilies-p">{t("home.step1")}</p>
                  </div>
                </div>
                <div className="col col--f">
                  <div
                    data-w-id="e6edfe9a-9b27-7149-031f-f3bdea14b9df"
                    className="faciles-cont"
                  >
                    <img
                      src="/images/fill.svg"
                      alt=""
                      className={`facilies-icon facilies-icon--${config.class_handle}`}
                    />
                    <p className="facilies-p">{t("home.step2")}</p>
                  </div>
                </div>
                <div className="col col--f">
                  <div
                    data-w-id="e6edfe9a-9b27-7149-031f-f3bdea14b9e4"
                    className="faciles-cont"
                  >
                    <img
                      src="/images/download.svg"
                      alt=""
                      className={`facilies-icon facilies-icon--${config.class_handle}`}
                    />
                    <p className="facilies-p">{t("home.step3")}</p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Home;
